import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Table } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment/locale/es';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class Empresas extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            loadingEmpresas: true,
            loadingMore: false,
            total: 0,
            empresas: [],
            parametro_busqueda: '',
            loadingBusqueda: false,
            filtro_rol: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.buscarUsuario = this.buscarUsuario.bind(this)

    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    componentDidMount(){
        this.getEmpresas(false)
    }

    buscar(){
        const { parametro_busqueda, user } = this.state
        if(!parametro_busqueda) return toast.error('El valor de búsqueda es requerido')
        if(parametro_busqueda.length < 3) return toast.error('Ingresa un valor de búsqueda válido')
        let loading = 'loadingBusqueda'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/empresas/search`,{
            method:'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
            body: JSON.stringify({ parametro_busqueda })
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(res.length < 1){
                toast.warn('No se encontraron resultados')
                return this.setState({ [loading]: false })
            }
            return this.setState({ empresas: res, filtro_rol:'', [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    async getEmpresas(cargarmas){
        const { user, empresas } = this.state
        let loading = 'loadingEmpresas'
        if(cargarmas===true) loading = 'loadingMore'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/empresas/list?skip=${empresas.length}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(cargarmas===true){
                if(Array.isArray(res.datos) !== false){
                    const final_array = empresas.concat(res.datos)
                    return this.setState({ empresas: final_array, total: res.total, [loading]: false })
                } else {
                    return this.setState({ [loading]: false })
                }
            } else {
                this.setState({ empresas: res.datos, total: res.total, [loading]: false })
            }
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    buscarUsuario(e){
        e.preventDefault()
        return this.buscar()
    }
    
    sinRegistros(){
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                <h4>Aún no tienes empresas</h4>
                <p>Puedes crear diversas empresas</p>
                <Link to="/empresas/nuevo"><Button size="sm" variant="primary">CREAR </Button></Link>
                </Col>
            </Row>
        </div>
    }

    mostrarEmpresas(){
        const { empresas, loadingEmpresas, loadingMore, total, filtro_rol, loadingBusqueda } = this.state
        if(loadingEmpresas) return <Spinner animation='border' />
        if(empresas.length < 1) return this.sinRegistros()

        const filtrados_rol = filtro_rol ? empresas.filter(user => user.role === filtro_rol) : empresas
        return <div>

            <form onSubmit={this.buscarUsuario}>
            <Row className='mb-3'>
                <Col md={2} className='mb-3'>
                    <label className='form-control-label d-block'>Buscar</label>
                    <input className='form-control' name="parametro_busqueda" onChange={this.handleChange} />
                </Col>
                <Col md={2} className='mb-3'>
                    <label className='form-control-label d-block'>Click para buscar</label>
                    {
                        loadingBusqueda === true ? <Spinner animation='border' /> : <Button size="sm" variant="outline-primary" onClick={()=>this.buscar()}>BUSCAR</Button>
                    }
                </Col>
                { /* <Col md={2}>
                    <label className='form-control-label d-block'>Filtrar por rol</label>
                    <select className='form-control' name="filtro_rol" value={filtro_rol} onChange={this.handleChange} >
                        <option value="" >Seleccione</option>
                        <option value="user" >Usuario</option>
                        <option value="admin" >Administrador</option>
                    </select>
                </Col> */ }
            </Row>
            </form>
            

<div className='p-2'>
<Tabs defaultActiveKey="activos" id="uncontrolled-tab-example" className="mb-3">
    <Tab eventKey="activos" title="Activas">
    <h5 className='mb-3'>Se muestran {filtrados_rol.length} de {total} registros</h5>
            <div className='table-responsive'>
            <Table bordered className='table'>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Razón social</th>
                        <th>Creación</th>
                        <th>Archivar</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filtrados_rol.map((empresa,i) => {
                            return <tr key={`user-${empresa._id}`}>
                                    <th><Link to={`/empresas/edit-${empresa._id}`}>{empresa.nombre_fantasia}</Link> </th>
                                    <th>{empresa.razon_social}</th>
                                    <th><Moment locale="ES" fromNow>{empresa.createdAt}</Moment></th>
                                    <th><Button variant="link" className="text-danger p-0" size="sm">Archivar</Button></th>
                                </tr>
                        })
                    }
                </tbody>

            </Table>
            {
                empresas.length < total ? <div>
                    {
                        loadingMore === true ? <Spinner animation='border' /> : <Button size="sm" variant="outline-primary" onClick={()=>this.getEmpresas(true)}>CARGAR MÁS</Button>
                    }
                </div> : false
                
            }
            
            </div>
    </Tab>
    <Tab eventKey="archivadas" title="Archivadas"></Tab>
</Tabs>
</div>

            
            
            
        </div>
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-top pb-2 mb-3">
      </div>
    <h4>Empresas </h4>
    <Link to="/empresas/nuevo"><Button size="sm" className="mb-3" variant="primary">CREAR</Button></Link>
      <Card >
          <Card.Body>
          {this.mostrarEmpresas()}
          </Card.Body>
      </Card>
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Empresas);