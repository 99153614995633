import { useEffect, useState } from 'react'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Select from 'react-select';

const CilindradaSelector = (props) => {

    const [ modelos, setModelos ] = useState([])
    const [ loadingModelos, setLoadingModelos ] = useState(true)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const token = props.token ? props.token : false
    const default_value = props.default_value ? props.default_value : false
    
    const obtenerModelos = async (page, query) => {
        setLoadingModelos(true)
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/productos/cilindradas`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingModelos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingModelos(false)
            } else if(Array.isArray(res) !== false){
                setModelos(res)
            }
            return setLoadingModelos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingModelos(false)
        })
    }

    const handleChangeSelectModelo = (id) => {
        if(props.handleSelectModelo) {
            const i = modelos.findIndex(m => m._id === id.value)
            if(i < 0) return false
            return props.handleSelectModelo(modelos[i])
        }
    }

    useEffect(() => {
        obtenerModelos()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const options = modelos.map(m => ({ value: m._id, label: m.titulo }))

    let valor = false
    if(default_value){
        const i_valor = options.findIndex(o => o.value === default_value )
        if(i_valor > -1) valor = options[i_valor]
    }
 
    return <div>
        <label className='form-control-label'>Cilindrada</label>
        <Select
                                      style={{ marginBottom: 10 }}
                                      onChange={handleChangeSelectModelo}
                                      options={options}
                                      isLoading={loadingModelos}
                                      value={valor}
                                      placeholder="Buscar cilindrada..."
                    />
    </div>

}

export default CilindradaSelector