import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Modal } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import CrearContactoForm from '../../subComponents/contactos/CrearContactoForm'
import EditarContactoForm from '../../subComponents/contactos/EditarContactoForm'
import Skeleton from 'react-loading-skeleton'

class EmpresasEditar extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            empresa: {
                activo: true
            },
            loadingEmpresa: true,
            comunas_descartar_creacion: [],
            guardandoCambios: false,
            showModalContacto: false,
            showModalContactoEditar: false,
            contactos: [],
            total_contactos: 0,
            proyectos: [],
            id_contacto_editar: false,
            filtro_contacto: ''
        }
        this.handleChangeEmpresa = this.handleChangeEmpresa.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
        this.handleChangeState = this.handleChangeState.bind(this)
    }

    handleChangeState(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeEmpresa(e){
        const { empresa } = this.state
        const { name, value } = e.target
        empresa[name] = value
        return this.setState({ empresa })
    }

    handleChangeChecked(checked){
        return this.setState({ show_password: checked })
    }

    crearContacto(){
        return this.setState({ showModalContacto: true })
    }

    handleClose(){
        return this.setState({ showModalContacto: false, showModalContactoEditar: false })
    }

    agregarContacto(contacto){
        const { contactos } = this.state
        contactos.push(contacto)
        return this.setState({ contactos, showModalContacto: false }) 
    }

    actualizarContacto(contacto){
        const { contactos } = this.state
        const i = contactos.findIndex(cont => cont._id === contacto._id)
        if(i < 0) return false
        contactos[i] = contacto
        return this.setState({ contactos, showModalContactoEditar: false })
    }

    modalEditarContacto(){
        const { showModalContactoEditar, id, id_contacto_editar, user } = this.state
        return <Modal show={showModalContactoEditar} size="lg" onHide={()=>this.handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Editar contacto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <EditarContactoForm
            token={user.tokenSession}
            id={id_contacto_editar}
            handleNewContact={(contacto) => this.actualizarContacto(contacto)}
            idempresa={id}
            />
        </Modal.Body>
        
      </Modal>
    }

    modalContacto(){
        const { showModalContacto, id, user } = this.state
        return <Modal show={showModalContacto} size="lg" onHide={()=>this.handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Crear contacto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CrearContactoForm
            token={user.tokenSession}
            handleNewContact={(contacto) => this.agregarContacto(contacto)}
            idempresa={id}
            />
        </Modal.Body>
        
      </Modal>
    }

    componentDidMount(){
        const { id } = this.state
        if(!id) return this.setState({ loadingUsuario: true })
        this.getEmpresa(id)
    }

    guardarCambios(){
        const { empresa, user } = this.state
        const requeridos = [
            { value:'razon_social', label: 'Razón social' },
            { value:'nombre_fantasia', label: 'Nombre fantasía' },
            { value:'id_fiscal', label: 'ID Fiscal' }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!empresa[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        const loading = 'guardandoCambios'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/empresas`,{
            method:'PUT',
            body: JSON.stringify( empresa ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(res._id){ 
                toast.success('Guardado exitosamente')
                setTimeout(() => {
                    return window.location = '/empresas'
                }, 500);
            } else {
                toast.error('Ocurrió un error inesperado')
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    async getContactosEmpresa(id){
        const { user } = this.state
        let loading = 'loadingContactos'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/contactos/list?idempresa=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            return this.setState({ contactos: res.datos, total_contactos: res.total, [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    async getEmpresa(id){
        const { user } = this.state
        let loading = 'loadingEmpresa'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/empresas/details?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            return this.setState({ empresa: res, [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    obtenerContacto(id_contacto_editar){
        return this.setState({ id_contacto_editar, showModalContactoEditar: true })
    }

    accionesContactos(){
        return <Button size="sm" className='mb-3' variant="primary" onClick={()=>this.crearContacto()}>AGREGAR</Button>
    }

    mostrarContactos(){
        const { loadingContactos, contactos, filtro_contacto } = this.state
        if(loadingContactos) return <div className='pt-2'>
            <h5>Contactos</h5>
            <Row>
                <Col md={3}><Skeleton count={2} /></Col>
                <Col md={3}><Skeleton count={2} /></Col>
                <Col md={3}><Skeleton count={2} /></Col>
                <Col md={3}><Skeleton count={2} /></Col>
            </Row>
        </div>
        if(!contactos) return false
        if(contactos.length < 1) return this.sinRegistros('Contactos', 'Aún no tienes contactos', 'Esta empresa tiene contactos creados', this.accionesContactos() )

        const filtro = filtro_contacto.toLowerCase();
        const contactosFiltrados = contactos.filter(item => {
            return Object.keys(item).some(key => {
              if(item[key]){
                return item[key].toString().toLowerCase().includes(filtro)
              }
              return false
            }
            );
        });

        return <div className='pt-2'>
            <h5>Contactos</h5>
            {this.accionesContactos()}
            <input className='form-control mb-3' name="filtro_contacto" placeholder='Buscar' onChange={this.handleChangeState} />
            <h6>{contactos.length} contactos</h6>
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Nombres</th>
                            <th>Apellidos</th>
                            <th>Email</th>
                            <th>Teléfono</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        contactosFiltrados.map(contacto => {
                            return <tr key={contacto._id}>
                                <th className='text-primary hover' onClick={()=>this.obtenerContacto(contacto._id)}>{contacto.nombres}</th>
                                <th>{contacto.apellidos}</th>
                                <th>{contacto.email}</th>
                                <th>{contacto.telefono}</th>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    }

    sinRegistros(titulo, subtitulo, mensaje, acciones){
        return <div>
            <h5>{titulo}</h5>
            <div className='p-5 mt-3'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                <h4>{subtitulo}</h4>
                <p className='mb-2'>{mensaje}</p>
                { acciones ? acciones : false}
                </Col>
            </Row>
        </div></div>
    }

    proyectos(){
        const { proyectos } = this.state
        if(proyectos.length < 1) return this.sinRegistros('Proyectos', 'Aún no tienes proyectos', 'Esta empresa no tiene proyectos creados')
    }

    formularioEmpresa(){
        const { guardandoCambios, empresa, loadingEmpresa } = this.state
        if(loadingEmpresa) return <Spinner animation='border' />
        return <div>
            <Row>
                <Col md={12}>
                    <h5>Datos principales</h5>
                <Row>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Razón social</label>
                        <input name="razon_social" defaultValue={empresa.razon_social} className='form-control' onChange={this.handleChangeEmpresa} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Nombre fantasía</label>
                        <input name="nombre_fantasia" defaultValue={empresa.nombre_fantasia} className='form-control' onChange={this.handleChangeEmpresa} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>ID Fiscal</label>
                        <input name="id_fiscal" defaultValue={empresa.id_fiscal} className='form-control' onChange={this.handleChangeEmpresa} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Dirección principal</label>
                        <input name="direccion_principal" defaultValue={empresa.direccion_principal} className='form-control' onChange={this.handleChangeEmpresa} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Teléfono</label>
                        <input name="telefono_principal" defaultValue={empresa.telefono_principal} className='form-control' onChange={this.handleChangeEmpresa} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>País</label>
                        <select name="sexo" className='form-control' onChange={this.handleChangeEmpresa} defaultValue={'CL'}>
                            <option value="">Selecciona</option>
                            <option value="CL">Chile</option>
                        </select>
                    </Col>
                    <Col md={12}>
                        {
                            guardandoCambios===true ? <Spinner animation='border' /> : <Button size="sm" variant='success' onClick={()=>this.guardarCambios()}>GUARDAR CAMBIOS </Button>
                        }
                    </Col>
                </Row>
                </Col>
            </Row>
            
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-top pt-3 pb-2 mb-3 border-bottom">
      <Link to={`/empresas`} className='text-dark' ><i className="fas fa-chevron-left"></i> Volver</Link>
      </div>
    <h1 className="h2">Editar empresa </h1>
            {this.modalContacto()}
            {this.modalEditarContacto()}
      <Card className='mb-3'>
          <Card.Body>
            {this.formularioEmpresa()}
          </Card.Body>
      </Card>
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(EmpresasEditar);