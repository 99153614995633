import { useState } from "react"
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import { key_local_storage_user } from "../../lib/backend/data"
import ProductosListado from "./listado"

const TraspasoAplicaciones = (props) => {
    const [ openModal, setOpenModal ] = useState(false)
    const token = props.token ? props.token : false
    const user = JSON.parse(localStorage.getItem(key_local_storage_user))

    const abrirModal = () => {
        const valor = openModal ? false : true
        return setOpenModal(valor)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const onSelectProduct = (e) => {
        if(props.onSelectProduct) props.onSelectProduct(e)
        handleClose()
    }

    const modalSeleccion = () => {
        return <Modal size='xl' show={openModal ? true : false} onHide={()=>handleClose()} centered >
        <Modal.Body>
            <ProductosListado 
                tipo="funcion" 
                token={user.tokenSession} 
                condicion_default={{ status: { $nin: ["trash"] } }} 
                onSelectProduct={(e) => onSelectProduct(e)}
            />
        </Modal.Body>
        </Modal>
    }

    return <div>
        {modalSeleccion()}
        <label className='form-control-label d-block'>Copiar aplicaciones</label>
        <OverlayTrigger
            placement={'top'}
            overlay={
                <Tooltip>Con esta opción podrás copiar estas aplicaciones dentro de otro producto</Tooltip>
            }
            >
            <Button variant='outline-primary' onClick={() => abrirModal()} >COPIAR</Button>
        </OverlayTrigger>
    </div>
}

export default TraspasoAplicaciones