import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Breadcrumb } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import Switch from "react-switch";
import { validateEmail } from '../../lib/helpers/helpers'
class UsuariosCrear extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            usuario: {
                activo: true
            },
            loadingUsuarios: false,
            comunas_descartar_creacion: [],
            show_password: false
        }
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
    }

    handleChangeUsuario(e){
        const { usuario } = this.state
        const { name, value } = e.target
        usuario[name] = value
        return this.setState({ usuario })
    }

    handleChangeChecked(checked){
        return this.setState({ show_password: checked })
    }

    crearUsuario(){
        const { usuario, user } = this.state
        const requeridos = [
            { value:'nombres', label: 'Nombres' },
            { value:'apellidos', label: 'Apellidos' },
            { value:'email', label: 'Email' },
            { value:'password', label: 'Contraseña' },
            { value:'role', label: 'Rol de usuario' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        if(validateEmail(usuario.email) !== true) return toast.error('Email inválido')
        if(usuario.password.length < 5) return toast.error('La contraseña debe tener al menos 5 carácteres')
        if(usuario.password !== usuario.password_confirm) return toast.error('Las contraseñas no coinciden')
        const loading = 'creandoUsuario'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios`,{
            method:'POST',
            body: JSON.stringify( usuario ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Ocurrió un error inesperado')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){ 
                toast.success('Guardado exitosamente, redirigiendo')
                setTimeout(() => {
                    return window.location = '/usuarios'
                }, 500);
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    formularioUsuario(){
        const { loadingUsuarios, show_password } = this.state
        if(loadingUsuarios) return <Spinner animation='border' />

        return <div>
            <Row>
                <Col md={3} className="mb-3">
                    <label className='form-control-label'>Nombres</label>
                    <input name="nombres" className='form-control' onChange={this.handleChangeUsuario} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label'>Apellidos</label>
                    <input name="apellidos" className='form-control' onChange={this.handleChangeUsuario} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label'>Email</label>
                    <input name="email" className='form-control' onChange={this.handleChangeUsuario} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label'>Rol de usuario</label>
                    <select name="role" className='form-control' onChange={this.handleChangeUsuario}>
                        <option value="">Selecciona</option>
                        <option value="user">Usuario</option>
                        <option value="admin">Administrador</option>
                    </select>
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label'>Contraseña</label>
                    <input name="password" type={show_password === true ? 'text' : 'password'} className='form-control' onChange={this.handleChangeUsuario} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label'>Repetir contraseña</label>
                    <input name="password_confirm" type={show_password === true ? 'text' : 'password'} className='form-control' onChange={this.handleChangeUsuario} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label d-block'>Mostrar contraseña</label>
                    <Switch onChange={this.handleChangeChecked} checked={show_password} />
                </Col>
                <Col md={12}>
                    <Button size="sm" variant='success' onClick={()=>this.crearUsuario()}>CREAR </Button>
                </Col>
            </Row>
            
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-top pb-2 mb-3">
      </div>
      <Breadcrumb>
        <Breadcrumb.Item href="#"><Link to="/usuarios">Usuarios</Link> </Breadcrumb.Item>
        <Breadcrumb.Item active>Crear</Breadcrumb.Item>
    </Breadcrumb>
    <h4>Crear nuevo usuario </h4>
      <Card >
          <Card.Body>
          {this.formularioUsuario()}
          </Card.Body>
      </Card>
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(UsuariosCrear);