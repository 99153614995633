const acciones = [
    { slug:'ver', label: "Ver" },
    { slug:'crear', label: "Crear" },
    { slug:'modificar', label: "Modificar" },
    { slug:'eliminar', label: "Eliminar" },
]

const logotipo = window.location.protocol + "//" + window.location.host + "/images/logo.png"

module.exports = {
    acciones,
    logotipo
}