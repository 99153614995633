import { Row, Col } from 'react-bootstrap'

const SinDatos = () => {
    return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={7} className="text-center">
                <h4>No se puede mostrar esta página</h4>
                <p>No se obtuvieron suficientes datos para mostrar el recurso que intentas visualizar</p>
                </Col>
            </Row>
        </div>
}

export default SinDatos