import { useEffect, useState } from 'react'
import { Row, Col, Button, Spinner } from 'react-bootstrap'
import { validateEmail } from '../../lib/helpers/helpers'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';

const EditarContactoForm = (props) => {
        const [ id ] = useState(props.id ? props.id : 'no')
        const [ contacto, setContacto ] = useState(false)
        const [ loading, setLoading ] = useState(true)
        const token = props.token

        
        
        const handleChangeContacto = (e) => {
                const { name, value } = e.target
                contacto[name] = value
                return setContacto(contacto)
        }

        const handleNewContact = (contacto) => {
                if(props.handleNewContact) return props.handleNewContact(contacto)
        }

        const obtenerContacto = async (id) => {
            return fetch(`${data.urlapi}/contactos/details?id=${id}`,{
                method:'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer: ${token}`
                }
            })
            .then(res => {
                if(res.status === 401) return window.location = '/login'
                return res.json()
            })
            .then(res => {
                if(res._id){ 
                    setContacto(res)
                }
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                toast.error("Error al realizar esta operación, intente nuevamente")
            })
        }

        const actualizarContacto = async () => {
                const requeridos = [
                    { value:'nombres', label: 'Nombres' },
                    { value:'email', label: 'Email' }
                ]
                let faltantes = []
                requeridos.map(campo => {
                    if(!contacto[campo.value]) faltantes.push(campo.label)
                    return true
                })
                if(faltantes.length > 0) return toast.error(`Lo siguientes campos son obligatorios: ${faltantes.join(', ')}`)
                if(validateEmail(contacto.email) !== true) return toast.error('Email inválido')
                setLoading(true)
                return fetch(`${data.urlapi}/contactos`,{
                    method:'PUT',
                    body: JSON.stringify( contacto ),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer: ${token}`
                    }
                })
                .then(res => {
                    if(res.status === 401) return window.location = '/login'
                    return res.json()
                })
                .then(res => {
                    if(res._id){ 
                        toast.success('Guardado exitosamente')
                        handleNewContact(contacto)
                    } else {
                        toast.error('Ocurrió un error inesperado')
                    }
                    setLoading(false)
                })
                .catch(error => {
                    setLoading(false)
                    toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
                })
            }

            useEffect(() => {
                obtenerContacto(id)
            }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if(loading===true) return <Spinner animation="border" />
    if(!contacto) return false
    return <div>
                <Row>
                       <Col xs={6} className="mb-2">
                                <label className='form-control-label'>Nombres</label>
                                <input name="nombres" defaultValue={contacto.nombres} className='form-control' onChange={handleChangeContacto} />
                       </Col>
                       <Col xs={6} className="mb-2">
                                <label className='form-control-label'>Apellidos</label>
                                <input name="apellidos" defaultValue={contacto.apellidos} className='form-control' onChange={handleChangeContacto} />
                       </Col>
                       <Col xs={6} className="mb-2">
                                <label className='form-control-label'>Email</label>
                                <input name="email" defaultValue={contacto.email} className='form-control' onChange={handleChangeContacto} />
                       </Col>
                       <Col xs={6} className="mb-2">
                                <label className='form-control-label'>Teléfono</label>
                                <input name="telefono" defaultValue={contacto.telefono} className='form-control' onChange={handleChangeContacto} />
                       </Col>
                       <Col xs={12}>
                               <hr />
                               { loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>actualizarContacto()}>GUARDAR CAMBIOS</Button>}
                       </Col>
                </Row>
        </div>
}

export default EditarContactoForm