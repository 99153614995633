import { useEffect, useRef, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Breadcrumb, Alert, Badge, Modal } from 'react-bootstrap'
import data, { urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays } from '../../lib/helpers/dates';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Link } from 'react-router-dom';
import Inventario from '../../subComponents/repuestos/inventario';
import SinDatos from '../../subComponents/general/sin_registros';
import { fechaATexto } from '../../lib/helpers/helpers';
import { stringByStatus } from '../../lib/helpers/pedidos/pedidos';
import { DateTime } from 'luxon';
import ReactJson from 'react-json-view'

const EditarPedido = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ ruta, setRuta ] = useState({})
    const [ aplicaciones, setAplicaciones ] = useState([])
    const [ conductores, setConductores ] = useState([])
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ loadingRuta, setLoadingRuta ] = useState(true)
    const [ conductor, setConductor ] = useState(false)
    const [ loadingGuia, setLoadingGuia ] = useState(false)
    const [ loadingEscaneo, setLoadingEscaneo ] = useState(false)
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ modelosMultiples, setModelosMultiple ] = useState([])
    const [ descripcion, setDescripcion ] = useState('')
    const [ showButtonUpdateDate, setShowButtonUpdateDate ] = useState(false)
    const [ loadingAplicacionId, setAplicacionId ] = useState(false)
    const [ loadingCopiado, setLoadingCopiado ] = useState(false)
    const [ loadingAcciones, setLoadingAcciones ] = useState(false)
    const [ loadingEmision, setLoadingEmision ] = useState(false)
    const [ guardandoNota, setGuardandoNota ] = useState(false)
    const [ nuevoCodigoImportar, setNuevoCodigoImportar ] = useState([])
    const [ codigo, setCodigo ] = useState("")
    const [ nota, setNota ] = useState("")
    const [ facturando, setFacturando ] = useState("")
    const [ folio, setFolio ] = useState("")
    const [ guardandoFolio, setGuardandoFolio ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ showModalFolio, setShowModalFolio ] = useState(false)
    const token = props.token ? props.token : false
    const idproducto = props.idproducto ? props.idproducto : false
    const inputEscaneo = useRef(false)

    useEffect(() => {
        obtenerProducto(idproducto)
    },[])

    const buscarPDFGuia = async (salesWaybillId, tok) => {
        setFacturando(true)
        return fetch(`https://api.laudus.cl/sales/waybills/${salesWaybillId}/pdf`,{
          method:'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${tok}`
          }
      })
      .then(res => {
          if(res.status === 401) {
            setFacturando(false)
            return toast.error("NO autorizado para consultar PDF")
          }
          return res.blob()
      })
      .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = `${salesWaybillId}.pdf`;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();    
              a.remove();
          return setFacturando(false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setFacturando(false)
      })
      }

    const emitirGuia = async (id) => {
        setLoadingGuia(true)
        return fetch(`${data.urlapi}/pedidos/guia?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingGuia(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingGuia(false)
            } else if(typeof res.docNumber !== "undefined"){
                toast.success(`Emitida exitosamente la guia ${res.docNumber}`)
                return buscarPDFGuia(res.docNumber, res.token)
            }
            return setLoadingGuia(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const reiniciarPedido = async (id) => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/pedidos/reset?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(Array.isArray(res) !== false){
                if(res.length > 0) return obtenerProducto(idproducto)
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }
    const obtenerProducto = async (id) => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/pedidos?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(res._id){
                setRuta(res)
                if(res.aplicaciones){
                    if(Array.isArray(res.aplicaciones) !== false){
                        setAplicaciones(res.aplicaciones)
                    }
                }
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const agregarNota = async () => {
        if(!nota) return toast.error("Ingresa el folio")
        const payload_ = {
            idrecurso: ruta._id,
            message: nota
        }
        setGuardandoNota(true)
        const controller = new AbortController();
            const id = setTimeout(() => {
                controller.abort()
            }, 10000 )
        return fetch(`${data.urlapi}/pedidos/notas`,{
            signal: controller.signal,
            method:'POST',
            body: JSON.stringify(payload_),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(datos =>{ 
            clearTimeout(id)
            if(datos.status === 401) return window.location = '/login'
            return datos.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setNota('')
                toast.success("Actualizado exitosamente")
                if(!ruta.notas) ruta.notas = []
                if(Array.isArray(ruta.notas) !== true) ruta.notas = []
                ruta.notas.unshift(res)
                setRuta({...{}, ...ruta})
            }
            return setGuardandoNota(false)
        })
        .catch(error => {
            clearTimeout(id)
            toast.error(`Error al consultar la información: ${error.message}`)
            return setGuardandoNota(false)
        })
    }

    const refrescarFolio = async () => {
        if(!folio) return toast.error("Ingresa el folio")
        const payload = {
            id: ruta._id,
            folio
        }
        setGuardandoFolio(true)
        const controller = new AbortController();
            const id = setTimeout(() => {
                controller.abort()
            }, 10000 )
        return fetch(`${data.urlapi}/pedidos/folio`,{
            signal: controller.signal,
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(datos =>{ 
            clearTimeout(id)
            if(datos.status === 401) return window.location = '/login'
            return datos.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Actualizado exitosamente")
                return setTimeout(() => {
                    window.location.reload()
                }, 100);
            }
            return setGuardandoFolio(false)
        })
        .catch(error => {
            console.log(error)
            clearTimeout(id)
            toast.error(`Error al consultar la información: ${error.message}`)
            return setGuardandoFolio(false)
        })
    }

    const actualizarPedidoProducto = async (payload, qty) => {
        let nuevo_payload = JSON.parse(JSON.stringify(payload))
        nuevo_payload.cantidad_pickeada = qty
        return fetch(`${data.urlapi}/pedidos/producto`,{
            method:'POST',
            body: JSON.stringify(nuevo_payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(res._id){
                return res
            }
            return false
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return false
        })
    }
    
    const agregarProducto = async (payload) => {
        payload.id_pedido = idproducto
        payload.cantidad_pickeada = payload.cantidad
        payload.statue = "active"
        delete payload.createdAt
        delete payload.updatedAt
        setLoadingAcciones(true)
        return fetch(`${data.urlapi}/pedidos/nuevo-producto`,{
            method:'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingAcciones(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingAcciones(false)
            } else if(res._id){
                ruta.productos.push(res)
                setNuevoCodigoImportar([])
                setRuta(prev => ({...{}, ...ruta}))
                toast.success("Agregado exitosamente")
            }
            return setLoadingAcciones(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingAcciones(false)
        })
    }

    const obtenerProveedores = async () => {
        setLoadingConductores(true)
        return fetch(`${data.urlapi}/conductores/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(Array.isArray(res) !== false){
                setConductores(res.map(c => ({ value: c._id, label: `${c.patente.toUpperCase()} · ${c.modelo.toUpperCase()}` })))
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const actualizarProducto = async () => {
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/pedidos`,{
            method:'PUT',
            body: JSON.stringify(ruta),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Guardada exitosamente')
                return setTimeout(() => {
                    window.location = `/product-edit-${idproducto}`
                }, 1000);
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCreacion(false)
        })
    }

    const handleChangeProducto = (e) => {
        const { name, value } = e.target
        ruta[name] = value
        return setRuta(ruta)
    }

    const cambiarRangoFecha = (item) => {
        setShowButtonUpdateDate(true)
        return setSelectionRange(item.selection)
    }

    const handleChangeSelectConductor = (e) => {
        return setConductor(e.value)
    }

    const confirmarCambioFecha = async () => {
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/rutas/fecha`,{
            method:'PUT',
            body: JSON.stringify({
                id: ruta._id,
                fecha_min_entrega: selectionRange.startDate,
                fecha_max_entrega: selectionRange.endDate,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Actualizado exitosamente')
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingCreacion(false)
        })
    }

    const solicitarCambioFecha = (id) => {
        return confirmAlert({
            title: `¿Cambio de fechas?`,
            message: `Actualizar el rango de entrega modificará individualmente cada orden siempre y cuando éstas no estén finalizadas.`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarCambioFecha()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const mostrarBotonActualizarFecha = () => {
        if(!showButtonUpdateDate) return false
        return <Button size="sm" className='w-100' onClick={()=>solicitarCambioFecha()} >ACTUALIZAR RANGO DE ENTREGA</Button>
    }

    const confirmarEliminadoRuta = () => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/pedidos?id=${ruta._id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = '/pedidos'
                }, 1000);
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingRuta(false)
        })
    }

    const solicitarEliminarRuta = () => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar esta ruta y todas las órdenes asociadas`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminadoRuta()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const reemitirLaudus = async () => {
        const {
            values
        } = DateTime.fromISO(ruta.fecha).diffNow("minutes")
        const minutos = Math.abs(values.minutes)
        console.log({minutos})
        const mensaje = "No puedes emitir a Laudus un pedido con menos de 15 minutos desde que se creó, esto es para evitar duplicación en la emisión de folios"
        if(minutos < 15) return setErrores(prev => [...prev, ...[mensaje]])

        setLoadingEmision(true)
        return fetch(`${data.urlapi}/pedidos/reprocess?id=${ruta._id}`, {
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
            return setLoadingEmision(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setLoadingEmision(false)
        })
    }

    const handleSelectModelo = (data, id) => {
        console.log({ data })
        const i = aplicaciones.findIndex(a => a._id === id)
        if(i < 0) return false
        aplicaciones[i].id_modelo = data._id
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const handleSelectCilindrada = (data, id) => {
        const i = aplicaciones.findIndex(a => a._id === id)
        if(i < 0) return false
        aplicaciones[i].id_cilindrada = data._id
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const handleChangeAplicacion = (e) => {
        const { name, value } = e.target
        const idapp = e.target.getAttribute("idapp")
        const pos = e.target.getAttribute("pos")
        if(idapp){
            const i = aplicaciones.findIndex(a => a._id === idapp)
            aplicaciones[i][name] = value
        } else {
            aplicaciones[pos][name] = value
        }
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const solicitarEliminarMensaje = (id) => {
        return confirmAlert({
            title: 'Confirmar',
            message: `Estás a punto de eliminar esta aplicación, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => eliminarApp(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    const eliminarApp = async (id) => {
        setAplicacionId(id)
        return fetch(`${data.urlapi}/pedidos/notas?id=${id}`, {
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Guardado exitosamente")
                const i = ruta.notas.findIndex(a => a._id === id)
                if(i > -1){
                    ruta.notas.splice(i,1)
                }
                setRuta({...{}, ...ruta})
            }
            return setAplicacionId(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setAplicacionId(false)
        })
    }

    const fetchCodigo = (codigo_busqueda) => {
        if(!codigo_busqueda) return false
        return fetch(`${data.urlapi}/productos/search-code`,{
            method: "POST",
            body: JSON.stringify({
                codigo_busqueda: codigo_busqueda.toUpperCase()
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            })
            .then(res => res.json())
            .then(resultados => {
                if(!resultados){
                    return []
                } else if(resultados.errorMessage){
                    toast.error(resultados.errorMessage)
                    return []
                } else if(Array.isArray(resultados) !== false){
                    return resultados
                }
                return []
            })
            .catch(error => {
                return []
            })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoadingEscaneo(true)
        const pos = ruta.productos.findIndex(p => {
            if(!p.codigo) return false
            return p.codigo.toString().toLowerCase() === codigo.toString().toLowerCase()
        })
        if(pos < 0){
            const encontrar = await fetchCodigo(codigo)
            setLoadingEscaneo(false)
            if(!encontrar || Array.isArray(encontrar) !== true) {
                if(encontrar.length < 1) return toast.error("Código no encontrado")
                return toast.error("Código no encontrado")
            }
            if(encontrar.length < 1){
                toast.error("Sin resultados de la búsqueda")
            }
            setNuevoCodigoImportar(encontrar.map(v => {
                v.cantidad = 1
                return v
            }))
            return false
        }
        const nueva_cantidad = ruta.productos[pos].cantidad_pickeada + 1
        if(nueva_cantidad > ruta.productos[pos].cantidad){
            setLoadingEscaneo(false)
            return toast.error("Cantidad requerida excedida")
        }
        const actualizarBD = await actualizarPedidoProducto(ruta.productos[pos], nueva_cantidad)
        if(!actualizarBD){
            setLoadingEscaneo(false)
            return toast.error("No se pudo actualizar, intenta de nuevo")
        }
        ruta.productos[pos].cantidad_pickeada = nueva_cantidad
        setLoadingEscaneo(false)
        toast.success(`Pickeado exitosmente ${ruta.productos[pos].titulo}`)
        return setRuta(prev => ({...{}, ...ruta}))
    }

    const mostrarMensajeEstado = () => {
        const incompletos = ruta.productos.filter(p => p.cantidad !== p.cantidad_pickeada)
        const listos = ruta.productos.filter(p => p.cantidad === p.cantidad_pickeada)
        const aumentados = ruta.productos.filter(p => p.cantidad_pickeada > p.cantidad)
        if(incompletos.length < 1) return <Alert variant="success"><i className="fas fa-check-circle"></i> TODO LISTO</Alert>
        return <Card className='p-3 mb-3'>
            <Row>
            <Col xs={4}>
                <h6 className='mb-0' style={{ fontSize: 12 }}>Productos pendientes</h6>
                <h2 className='mb-0'>{incompletos.length}</h2>
            </Col>
            <Col xs={4}>
                <h6 className='mb-0' style={{ fontSize: 12 }}>Productos pickeados</h6>
                <h2 className='mb-0'>{listos.length}</h2>
            </Col>
            <Col xs={4}>
                <h6 className='mb-0' style={{ fontSize: 12 }}>Productos excedidos</h6>
                <h2 className='mb-0'>{aumentados.length}</h2>
            </Col>
            </Row>
        </Card>
    }

    const handleChangeCodigo = (e) => {
        const { value } = e.target
        return setCodigo(value)
    }

    const chekcIcon = producto => {
        if(producto.cantidad_pickeada === producto.cantidad){
            return <i className="mr-2 text-success fas fa-check-circle"></i>
        } else if (producto.cantidad_pickeada > producto.cantidad ){
            return <i className="mr-2 text-primary fa-solid fa-badge-check"></i>
        }
    }

    const handleChangeCantidad = (e) => {
        const { value } = e.target
        const pos = parseInt(e.target.getAttribute("pos"))
        console.log(pos)
        nuevoCodigoImportar[pos].cantidad = parseInt(value)
        return setNuevoCodigoImportar(prev => [...[], ...nuevoCodigoImportar])
    }
    
    const mostrarErrores = () => {
        if(errores.length < 1) return false

        return <div className='mt-3'>
            {
                errores.map((error,i) => {
                    return <Alert key={`error-${i}`} variant='danger'>
                        <p className='mb-0'>{error}</p>
                    </Alert>
                })
            }
        </div>
    }

    const handleChangeNota = (e) => {
        const { name, value } = e.target
        return setNota(value)
    }

    const mostrarNotas = () => {
        if(!ruta) return <SinDatos/>
        if(!ruta.notas) return <SinDatos/>
        if(Array.isArray(ruta.notas) !== true) return <SinDatos/>

        return <Card className='p-3'>
        <h3 className='mb-0'><i className="fas fa-search"></i> Notas</h3>
        <hr className='mb-2 mt-2' />

        <label className='form-control-label d-block'>Ingresa una nueva nota</label>
        <input className='form-control mb-2' value={nota} onChange={handleChangeNota} />
        { guardandoNota ? <Spinner animation='border' /> : <Button className='w-100 mb-3' onClick={() => agregarNota()}>GUARDAR NOTA</Button>}

        <h4>{ruta.notas.length} Registros</h4>
            {
                ruta.notas.map(log => {
                    return <div key={log._id}>
                        <Card className='p-3 mb-3' variant={log.type}>
                            <Row className='mb-3'>
                                <Col><p className='mb-0' style={{ fontSize: 14 }}><b>{fechaATexto(log.fecha)}</b></p></Col>
                                <Col>{ loadingAplicacionId === log._id ? <Spinner animation='border' /> : <Button size="sm" className='w-100' style={{ fontSize: 10 }} variant='outline-danger' onClick={() => solicitarEliminarMensaje(log._id)} >ELIMINAR</Button>}</Col>
                            </Row>
                            <hr className='m-0' />
                            <p className='mt-3 mb-0'>{log.message}</p>
                        </Card>
                    </div>
                })
            }
            </Card>
    }

    const mostrarLogs = () => {
        if(!ruta) return <SinDatos/>
        if(!ruta.logs) return <SinDatos/>
        if(Array.isArray(ruta.logs) !== true) return <SinDatos/>

        return <Card className='p-3'>
        <h3 className='mb-0'><i className="fas fa-search"></i> Mensajes</h3>
        <hr className='mb-2 mt-2' />
        <h4>{ruta.logs.length} Registros</h4>
            {
                ruta.logs.map(log => {
                    return <div key={log._id}>
                        <Alert variant={log.type}>
                            { log.url ? <Badge variant="secondary"><b>URL</b> {log.url.toUpperCase()}</Badge> : <Badge variant="secondary"><b>SIN DATOS DE LA URL</b></Badge> }
                            <p className='mb-0' style={{ fontSize: 12 }}><b>{fechaATexto(log.fecha)}</b></p>
                            <p className='mb-2'>{log.message}</p>
                            { typeof log.data === "object" ? <Card className='p-3'><h6 className='mb-2 text-dark'>DATOS ENVIADOS A LAUDUS EN JSON</h6><ReactJson collapsed={0} name="payload_enviado" style={{ fontSize: 12 }} src={log.data} /></Card> : false }
                            { typeof log.response === "object" ? <Card className='p-3'><h6 className='mb-2 text-dark'>DATOS RECIBIDOS DE LAUDUS EN JSON</h6><ReactJson collapsed={0} name="payload_recibido" style={{ fontSize: 12 }} src={log.response} /></Card> : false }
                        </Alert>
                    </div>
                })
            }
            </Card>
    }

    const mostrarAcciones = () => {
        if(loadingAcciones) return <div>
            <Spinner animation='border' />
            <h5>Espere un momento</h5>
        </div>
        if(nuevoCodigoImportar.length > 0){
            return <div>
                <Card className='p-2 mb-3 text-light' bg="primary">
                    <Button variant="link" className='text-light text-left' size="sm" onClick={() => setNuevoCodigoImportar([]) }>CANCELAR</Button>
                    <h3 className='mb-0'>Agregaste un código que no está en este pedido</h3>
                    <hr className='mb-2' />
                    {
                        nuevoCodigoImportar.map((code,pos) => {
                            return <div key={code._id}>
                                <h5>{code.titulo}</h5>
                                <h4>{code.codigo}</h4>
                                <input defaultValue={code.cantidad} className='form-control mb-3' placeholder='Introduce la cantidad' pos={pos} onChange={handleChangeCantidad} type="number" />
                                <Button size="sm" variant='light' onClick={() => agregarProducto(code)}>AGREGAR ESTE CÓDIGO</Button>
                                {nuevoCodigoImportar.length === (pos+1) ? false : <hr className='text-white mb-2' /> }
                            </div>
                        })
                    }
                </Card>
            </div>
        }
    }

    const abrirEmisionFolio = () => {
        setShowModalFolio( showModalFolio === true ? false : true )
    }

    const handleClose = () => {
        setShowModalFolio(false)
    }

    const handleChangeFolio = (e) => {
        const { name, value } = e.target
        setFolio(value)
    }

    const modalFolio = () => {
        return  <Modal show={showModalFolio} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Guardar folio manualmente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <label className='form-control-label d-block'>FOLIO</label>
            <input className='form-control mb-3' type="number" defaultValue={folio} onChange={handleChangeFolio} />
            { guardandoFolio ? <Spinner animation='border' /> : <Button className='w-100' variant="success" onClick={() => refrescarFolio()} ><i className="fas fa-save"></i> GUARDAR FOLIO</Button> }
        </Modal.Body>
        
      </Modal>
    }

    const mostrarFolioAcciones = (value) => {
        const numero = parseInt(value)
        if(isNaN(numero)) return <div>
            <h3 className='mb-0' style={{ color: value === "errores" ? "red": "" }} >{value}</h3>
            <Button size="sm" variant='outline-primary' onClick={() => abrirEmisionFolio()}>DEFINIR FOLIO MANUALMENTE</Button>
            {modalFolio()}
        </div>
        return <h3 className='mb-0' style={{ color: value === "errores" ? "red": "" }} >{value}</h3>
    }

    const mostrarProductos = (pros) => {
        if(!pros) return <SinDatos/>
        if(Array.isArray(pros) !== true) return <SinDatos/>
        return <div>
            <form onSubmit={handleSubmit}>
            { loadingEscaneo ? <Spinner animation='border' className='mb-3' /> : <input className='form-control mb-3' onChange={handleChangeCodigo} placeholder='ESCANEA EL CÓDIGO DEL PRODUCTO' ref={inputEscaneo} autoFocus /> }
            </form>
            {mostrarAcciones()}
            {mostrarMensajeEstado()}
            <h4>{ruta.productos.length} Productos</h4>
            <Row>
                <Col xs={4} style={{ fontSize: 12 }}>Producto</Col>
                <Col xs={2} style={{ fontSize: 12 }}>Código</Col>
                <Col xs={2} style={{ fontSize: 12 }}>Código proveedor</Col>
                <Col xs={2} style={{ fontSize: 12 }}>Cantidad</Col>
                <Col xs={2} style={{ fontSize: 12 }}>Pickeado</Col>
            </Row>
            <hr className='mb-2' />
            {
                ruta.productos.map(producto => {
                    return <Row  key={`${producto.id}`}>
                        <Col xs={4} className="mb-3">{chekcIcon(producto)}{producto.titulo}</Col>
                        <Col xs={2} className="mb-3">{producto.codigo}</Col>
                        <Col xs={2} className="mb-3">{producto.codigo_proveedor}</Col>
                        <Col xs={2} className="mb-3">{producto.cantidad}</Col>
                        <Col xs={2} className="mb-3">{producto.cantidad_pickeada}</Col>
                    </Row>
                })
            }
        </div>
    }

    if(loadingRuta === true) return <Spinner animation='border' />
    if(Object.keys(ruta).length < 1) return <Row className="justify-content-md-center">
    <Col md={6} className="text-center">
      <img src="images/pedidos.png" style={{ width: "50%" }} />
    <h4>No hay datos suficientes para visualizar esta sección</h4>
    </Col>
    </Row>
    const i = conductores.findIndex(c => c.value === ruta.id_conductor)
    let default_vehiculo = false
    if(i > -1) default_vehiculo = conductores[i]

    return <div>
        <Breadcrumb>
        <Breadcrumb.Item href="#"><Link to="/pedidos">Pedidos</Link> </Breadcrumb.Item>
        <Breadcrumb.Item active>Editar</Breadcrumb.Item>
        </Breadcrumb>
        <hr />
    <Row>
        <Col md={12}>
            <Row>
            <Col md={12}>
                <p className='mb-0'>{fechaATexto(ruta.fecha)}</p>
                <h4>PEDIDO {ruta.pedido}</h4>
                {
                    loadingEmision ? <Spinner animation='border' /> : <Button variant='primary' size="sm" onClick={() => reemitirLaudus()}>EMITIR A LAUDUS</Button>
                }
                {mostrarErrores()}
                <hr />
            </Col>
            </Row>
        </Col>
        <Col md={12} className="mb-3">
            <Row>
                <Col className='mb-3'>
                    <Card className='p-3'>
                    <h6 className='mb-0'>ESTADO</h6>
                    <h3 className='mb-0'>{ stringByStatus(ruta.status) }</h3>
                    </Card>
                </Col>
                <Col className='mb-3'>
                    <Card className='p-3'>
                    <h6 className='mb-0'>DOCUMENTO</h6>
                    <h3 className='mb-0' >{ruta.tipo ? ruta.tipo.toUpperCase() : 'SIN DATOS'}</h3>
                    </Card>
                </Col>
                <Col className='mb-3'>
                    <Card className='p-3'>
                    <h6 className='mb-0'>FOLIO</h6>
                    {mostrarFolioAcciones(ruta.invoiceId)}
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={6}>{mostrarLogs()}</Col>
                <Col md={6}>{mostrarNotas()}</Col>
            </Row>
            { /* loadingCreacion === true ? <Spinner animation='border' /> : <Button size="sm" variant="outline-primary" className='mr-2' onClick={()=>reiniciarPedido(idproducto)}>REINICIAR PEDIDO AL ORIGINAL</Button> */ }
            { /* loadingGuia === true || facturando === true ? <Spinner animation='border' /> : <Button size="sm" variant="outline-primary" className='mr-2' onClick={()=>emitirGuia(idproducto)}>EMITIR GUIA DE DESPACHO</Button> */ }
        </Col>
        <Col md={12}>

        </Col>
    </Row>
</div>
}

export default EditarPedido