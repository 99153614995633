import { useEffect, useRef, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Spinner, Card, OverlayTrigger, Tooltip, Table, Form, Alert } from 'react-bootstrap'
import data, { key_local_storage_user, urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Link } from 'react-router-dom';
import { acciones } from '../../lib/global/data';
import { fechaATexto, showAmmount } from '../../lib/helpers/helpers';
import Conteos from './conteos';
import { stringByStatus } from '../../lib/helpers/pedidos/pedidos';
import { DateTime } from 'luxon';

const PedidosListado = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const [ equipos, setEquipos ] = useState([])
    const [ estados, setEstados ] = useState([])
    const [ loadingEsquemaEstados, setloadingEsquemaEstados ] = useState(true)
    const [ loadingEquipos, setLoadingEquipos ] = useState(true)
    const [ patente, setPatente ] = useState('')
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ filtroEstado, setFiltroEstado ] = useState(false)
    const [ filtroSubEstado, setFiltroSubEstado ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const token = props.token ? props.token : false
    const trash = props.trash ? props.trash : false
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ rutaBusqueda, setRutaBusqueda ] = useState('')
    const [ codigoBusqueda, setCodigoBusqueda ] = useState('')
    const [ numPedidoJump, setNumPedidoJump ] = useState('')
    const [ resultadosCodigoBusqueda, setResultadosCodigoBusqueda ] = useState([])
    const [ loadingCodigos, setLoadingCodigos ] = useState(false)
    const [ loadingPedidoJump, setLoadingPedidoJump ] = useState(false)
    const [ idsSeleccionados, setIdsSeleccionados ] = useState([])
    const [ loadingEmision, setLoadingEmision ] = useState(false)
    const tipo = props.tipo ? props.tipo : "link"
    const abortController = useRef(null)
    const [ errores, setErrores ] = useState([])

    const obtenerProductos = async (page, query)=>{
        setPagina(page)
        setLoadingEquipos(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        setCondicionBusqueda(condicion)
        return fetch(`${data.urlapi}/pedidos/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingEquipos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingEquipos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setEquipos(res.datos)
                setTotal(res.total)
            }
            return setLoadingEquipos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEquipos(false)
        })
    }
    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerProductos(page)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChangeSelectEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroEstado(opciones)
    }
    
    const handleChangeSelectSubEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroSubEstado(opciones)
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control'>
                        <option value="createdAt">Creación</option>
                        <option value="updatedAt">Actualización</option>
                        <option value="compromiso">Compromiso</option>
                        <option value="ruta">Fecha ruta</option>
                    </select>
                </Col>
                <Col md={12}>
                    <Button size="sm" variant="success">FILTRAR</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div style={{ overflowX: "scroll" }}>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {
        obtenerProductos(1, false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onRefreshOrders = (conteo) => {
        const {
            condicion
        } = conteo
        obtenerProductos(1, condicion)
    }

    const obtenerEsquemaEstados = () => {
      return fetch(`${data.urlapi}/ordenes/suite-config-estados`,{
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
      .then(pros => pros.json())
      .then(data => { 
          console.log(data)
            if(!data){
            toast.error('Sin datos')
            return setRemoviendo(false)
            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return setRemoviendo(false)
            } else if(Array.isArray(data) !== false){
                if(data.length < 1) toast.warn('No hay esquema de estados y subestados')
                setEstados(data)
            }
            return setloadingEsquemaEstados(false)
      })
      .catch(error => {
          toast.error('No pudimos cargar la información')
          setloadingEsquemaEstados(false)
      })
    }

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const accionesBotones = (pro) => {
        if(trash === true) {
            return removiendo === true ? <Spinner animation="border" /> : <div>
            <Button variant="link" size="sm" className='pr-3 pt-0 pb-0 text-danger' onClick={()=>solicitarEliminar(pro._id)}>ELIMINAR DEFINITIVAMENTE</Button>
            <Button variant="link" size="sm" className='pr-3 pt-0 pb-0' onClick={()=>restaurarRegistro(pro._id,'active')}>RESTAURAR</Button>
            </div>
        }
        return removiendo === true ? <Spinner animation="border" /> : <div>
        <Button variant="link" size="sm" className='pr-3 pt-0 pb-0 text-danger' onClick={()=>restaurarRegistro(pro._id,'trash')}>ARCHIVAR</Button>
        </div>
    }

    const seleccionarProducto = (pro) => {
        if(props.onSelectProduct) props.onSelectProduct(pro)
    }

    const showComponentByType = (pro) => {
        switch (tipo) {
            case "funcion":
                return <p className='hover' onClick={() => seleccionarProducto(pro)}><b>{pro.pedido}</b></p>
            default:
                return <Link to={`pedidos-edit-${pro._id}`}>{pro.pedido}</Link>
        }
    }

    const colorByStatus = (val) => {
        switch (val) {
            case "PENDIENTE":
                return "blue"
            case "LISTO":
                return "green"
            default:
                return "black"
        }
    }

    const handleChangeCheck = (e) => {
        validarCheckeds()
    }

    const setValuesToCheckBox = value => {
        const checkboxes = document.querySelectorAll('.checkpedido input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = value;
        }
        validarCheckeds()
    }

    const validarCheckeds = () => {
        setTimeout(() => {
            const checkboxElements = document.querySelectorAll('.checkpedido input');
            const pedidos = [];
            for (let i = 0; i < checkboxElements.length; i++) {
                if (checkboxElements[i].type === 'checkbox' && checkboxElements[i].checked) {
                pedidos.push(checkboxElements[i].id);
                }
            }
            console.log(pedidos)
            setIdsSeleccionados(pedidos)
        }, 200);
    }

    const mostrarErrores = () => {
        if(errores.length < 1) return false

        return <div className='mt-3'>
            {
                errores.map((error,i) => {
                    return <Alert key={`error-${i}`} variant='danger'>
                        <p className='mb-0'>{error}</p>
                    </Alert>
                })
            }
        </div>
    }

    const envioMasivo = () => {
        const checkboxElements = document.querySelectorAll('.checkpedido input');
            const ids = [];
            for (let i = 0; i < checkboxElements.length; i++) {
                if (checkboxElements[i].type === 'checkbox' && checkboxElements[i].checked) {
                ids.push(checkboxElements[i].id);
                }
            }


        const filtrados = equipos.filter(pedido => ids.includes(pedido._id))
        console.log(filtrados)
        let errores_encontrados = []
        for( const pedido of filtrados ){
            const {
                values
            } = DateTime.fromISO(pedido.fecha).diffNow("minutes")
            const minutos = Math.abs(values.minutes)
            console.log({minutos})
            const mensaje = `No puedes emitir a Laudus el pedido ${pedido.pedido} porque tiene menos de 15 minutos desde que se creó, esto es para evitar duplicación en la emisión de folios`
            if(minutos < 15) errores_encontrados.push(mensaje)
        }
        if(errores_encontrados.length > 0) return setErrores(prev => [...[], ...errores_encontrados])
        procesarMasivo(ids)
    }

    const procesarMasivo = async (ids) => {
        if(ids.length < 1) return toast.info("Selecciona al menos un pedido")
        setLoadingEmision(true)
        return fetch(`${data.urlapi}/pedidos/masive`,{
            method:'POST',
            body: JSON.stringify({
                ids
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                toast.info("Proceso finalizado")
                obtenerProductos(pagina, false)
            }
            return setLoadingEmision(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEmision(false)
        })
    }
    
    const folioPorValor = (value) => {
        const numero = parseInt(value)
        if(isNaN(numero)){
            switch (value) {
                case 'errores':
                    return "ERRORES DETECTADOS ANTES DE EMITIR"
                case 'undefined':
                    return "SIN FOLIO OBTENIDO"
                case '':
                    return "SIN INFORMACIÓN"
                default:
                    return value
            }
        }
        return value
    }

    const tabla_registros = (registros) => {
        if(loadingEquipos===true) return cargandoScreen()
        if(registros.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>

        const full = registros.length === data.pagina
        const inicio = (pagina * data.pagina - data.pagina) + 1
        const fin = full ? (inicio-1) + data.pagina : Math.min(total, ((inicio-1) + registros.length))

        return <div>
            <div className='mb-3'>
            <Button size="sm" className='mr-3' variant="light" onClick={() => setValuesToCheckBox(true)}>SELECCIONAR TODOS</Button>
            <Button size="sm" className='mr-3' variant="light" onClick={() => setValuesToCheckBox(false)}>DESELECCIONAR TODOS</Button>
            { idsSeleccionados.length > 0 ? <div className='mt-3'>
                { loadingEmision ? <Spinner animation='border' /> : <Button size="sm" className='mr-3' onClick={() => envioMasivo()}>ENVIAR A LAUDUS</Button> }
            </div> : false }

            {mostrarErrores()}

            </div>
            <h5>{inicio} - {fin} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            <Table>
                <thead>
                    <th></th>
                    <th>ID</th>
                    <th>Folio / Num pedido</th>
                    <th>Creación</th>
                    <th>Status</th>
                </thead>
                <tbody>
                    {
                        registros.map(orden => {
                            return <tr key={orden._id} className="mb-3">
                                <th>
                                <div className='checkpedido'>
                                <Form.Check type="checkbox" id={orden._id} onChange={handleChangeCheck} />
                                </div>
                                </th>
                                <th>{showComponentByType(orden)}</th>
                                <th style={{ color: orden.invoiceId === "errores" ? "red": "" }}>{folioPorValor(orden.invoiceId)}</th>
                                <th>{fechaATexto(orden.fecha)}</th>
                                <th style={{ color: colorByStatus(orden.status), fontWeight: 'bold' }}>{stringByStatus(orden.status)}</th>
                            </tr>
                        })
                    }
                    </tbody>
            </Table>
        </div>
    }

    const restaurarRegistro = async (id,status) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/pedidos/status?id=${id}&status=${status}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = equipos.findIndex(e => e._id === id)
                equipos.splice(i,1)
                setEquipos(prev => equipos)
                setTimeout(() => {
                    window.location = '/pedidos'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }
    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/pedidos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = equipos.findIndex(e => e._id === id)
                equipos.splice(i,1)
                setEquipos(prev => equipos)
                setTimeout(() => {
                    window.location = '/pedidos'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const handleSubmitRuta = (e) => {
        e.preventDefault()
        if(!rutaBusqueda){
            const condicion = {  }
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerProductos(1, condicion)
        }
        const condicion = { $text: { $search: rutaBusqueda }, }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerProductos(1, condicion)
    }
    
    const handleSubmitJump = (e) => {
        e.preventDefault()
        if(loadingPedidoJump) return

        if(!numPedidoJump){
            return toast.error("Introduce un número de pedido")
        }
        setLoadingPedidoJump(true)
        return fetch(`${urlapi}/pedidos/jumpseller/sync?num=${numPedidoJump}`, {
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(resultados => {
            if(!resultados){
                toast.error("Sin datos del servidor")
            } else if(resultados.errorMessage){
                toast.error(resultados.errorMessage)
            } else if(resultados.status === "exist"){
                toast.info("Este pedido ya existe en esta base de datos")
                const condicion = { $text: { $search: numPedidoJump }, }
                setCondicionBusqueda(condicion)
                setPagina(1)
                obtenerProductos(1, condicion)
            } else if(resultados.status === "new"){
                toast.success("Sincronización realizada")
                const condicion_2 = { $text: { $search: numPedidoJump }, }
                setCondicionBusqueda(condicion_2)
                setPagina(1)
                obtenerProductos(1, condicion_2)
            }
            return setLoadingPedidoJump(false)
        })
        .catch(error => {
            toast.error('No pudimos cargar la información')
            return setLoadingPedidoJump(false)
        })
    }

    const reiniciar = () => {
        setPagina(1)
        setCondicionBusqueda({})
        obtenerProductos(1, {})
    }

    const abrirFiltro = () => {
        return setOpenModal(true)
    }

    const handleChangeRuta = (e) => {
        const { value } = e.target
        return setRutaBusqueda(value)
    }

    const handleChangeNumPedidoJump = (e) => {
        const { value } = e.target
        return setNumPedidoJump(value)
    }

    const fetchCodigo = (codigo_busqueda) => {
        if(!codigo_busqueda) return false
        abortController.current = new AbortController()
        setLoadingCodigos(true)
        return fetch(`${data.urlapi}/pedidos/search-code`,{
            signal: abortController.current.signal,
            method: "POST",
            body: JSON.stringify({
                codigo_busqueda: codigo_busqueda.toUpperCase()
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            })
            .then(res => res.json())
            .then(resultados => {
                if(!resultados){
                    toast.error("Sin datos del servidor")
                } else if(resultados.errorMessage){
                    toast.error(resultados.errorMessage)
                } else if(Array.isArray(resultados) !== false){
                    setResultadosCodigoBusqueda(resultados)
                }
                return setLoadingCodigos(false)
            })
            .catch(error => {
                return setLoadingCodigos(false)
            })
    }

    const cancelRequest = () => abortController.current && abortController.current.abort();

    const handleChangeCodigo = (e) => {
        const { value } = e.target
        if(value){
            if(value.length > 3){
                cancelRequest()
                fetchCodigo(value)
            }
        }
        return setCodigoBusqueda(value)
    }

    const onResultados = (data) => {
        return setEquipos(data)
    }

    const busquedaCodigo = () => {
        if(loadingCodigos) return <div style={{ position: "absolute", zIndex: 999, width: "100%" }}>
            <Card className='p-3'>
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            </Card>
        </div>
        return false
        if(resultadosCodigoBusqueda.length < 1) return false

        return <div style={{ position: "absolute", zIndex: 999, width: "100%" }}>
            <Card className='p-3'>
            {
                resultadosCodigoBusqueda.map(codigo => {
                    return <div key={`${codigo._id}`}>
                        {showComponentByType(codigo)} ${showAmmount(codigo.precio)}
                    </div>
                })
            }
            </Card>
        </div>
    }
 
    return <div>
        {modalFiltro()}
                <Row>
                    <Col md={4}>
                        <form onSubmit={handleSubmitRuta}>
                            <label className='form-control-label'>Buscar</label>
                            <input className='mb-3 form-control' placeholder='BUSCAR POR NÚMERO DE PEDIDO' onChange={handleChangeRuta} />
                        </form>
                    </Col>
                    <Col md={8}>
                        <form onSubmit={handleSubmitJump}>
                            <label className='form-control-label d-block'>Sincronizar <img style={{ height: 20 }} src={"../images/jumpseller.png"} /></label>
                            {
                                loadingPedidoJump ? <Spinner animation="border" /> : <input className='mb-3 form-control' defaultValue={numPedidoJump} placeholder='ESCRIBE EL NÚMERO DE PEDIDO PARA TRAERLO DESDE JUMPSELLER, SE ENVIARÁ A LAUDUS AUTOMÁTICAMENTE' onChange={handleChangeNumPedidoJump} />
                            }
                        </form>
                    </Col>
                </Row>
                <Conteos token={token} onRefresh={(conteo) => onRefreshOrders(conteo)} />
                {tabla_registros(equipos)}
    </div>

}

export default PedidosListado