import { useEffect, useState } from "react"
import { Button, Card, Col, Row, Spinner } from "react-bootstrap"
import { urlapi } from "../../lib/backend/data"
import { toast } from "react-toastify"
import { montoMoneda } from "../../lib/helpers/helpers"
import { obtenerFechaZonaHorariaLocal } from "../../lib/helpers/dates"

const Conteos = (props) => {
    const {
        token
    } = props
    const [ loadingConteos, setLoadingConteos ] = useState(true)
    const [ descargandoExcel, setDescargandoExcel ] = useState(false)
    const [ conteos, setConteos ] = useState([])

    useEffect(() => {
        obtenerConteos()
    }, [])

    const descargarReporte = async (condicion) => {
        setDescargandoExcel(true)
        const url = `${urlapi}/pedidos/reporte`
        return fetch(url,{
          method:'POST',
          body: JSON.stringify({
            condicion
          }),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
      .then(res => res.blob())
      .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setDescargandoExcel(false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setDescargandoExcel(false)
      })
      }

    const obtenerConteos = async () => {
        setLoadingConteos (true)
        const url = `${urlapi}/pedidos/conteo`
        return fetch(url,{
          method:'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
      .then(res => res.json())
      .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                 toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setConteos(res)
            }
            return setLoadingConteos (false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setLoadingConteos (false)
      })
      }

      const refrescarDatos = (conteo) => {
        if(props.onRefresh) props.onRefresh(conteo)
      }

      const mostrarConteos = () => {
        if(conteos.length < 1) return false

        return <div className="mb-3">
            <Row>
            {
                conteos.map((conteo,i) => {
                    return <Col key={`col-${i}`} >
                        <Card className="p-3">
                        <div className="hover" onClick={() => refrescarDatos(conteo)}>
                            <h6 className="mb-0">{conteo.titulo}</h6>
                            <h3 className="mb-0">{montoMoneda(conteo.total)}</h3>
                        </div>
                        { conteo.excel === true ? <div>
                        { descargandoExcel ? <Spinner size="sm" animation="border" /> : <Button size="sm" variant="outline-success" style={{ fontSize: 12 }} onClick={() => descargarReporte(conteo.condicion)} ><i className="fas fa-file-excel"></i> DESCARGAR REPORTE</Button> }
                        </div> : false }
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
      }

      return <div>
        {mostrarConteos()}
      </div>
}

export default Conteos