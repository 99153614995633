import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Accordion, Badge, Button, Card, Form, Modal, Table } from 'react-bootstrap'
import Header from '../Header'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import Select from 'react-select';
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { urlapi, key_local_storage_cart, key_local_storage_user } from '../../lib/backend/data'
import { montoMoneda, showAmmount } from '../../lib/helpers/helpers'
import Inventario from '../../subComponents/repuestos/inventario'
import BuscadorCodigo from '../../subComponents/productos/buscador_codigo'
import { checkPermission } from '../../lib/helpers/session'


const Home = (props) => {
      const user = localStorage.getItem(key_local_storage_user) ? JSON.parse(localStorage.getItem(key_local_storage_user) ) : {}
      return(
            <div className="fluid">
              <Header />
          <div className="container-fluid">
            <div className="row">
              <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                  <h1 className="h2">Bienvenido</h1>
                </div>

                <h3 style={{ fontWeight:100}}>Hola {user.data.nombres}</h3>

              </main>
            </div>
          </div>
    </div>
        )
    
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default Home