const { DateTime } = require("luxon");

const formatDateHoy = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('-')
}

const formatMes = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return month;
}

const formatDateMonth = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month ].join('-')
}

const formatDateYear = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year].join('-')
}

const addMoreDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result
  }

const restDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result
}

const formatDateHoyFromDate = (date) => {
    var d = addMoreDays(new Date(date),1),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('-')
}

const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

const configuracion_fechas = {
    weekday: 'long', 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric'
}

const configuracion_horas = {
    timeZone: 'America/Santiago',
    hour: '2-digit',
    minute: '2-digit'
}

const obtenerFechaZonaHorariaLocal = () => {
    const now = DateTime.now()
    now.setZone("America/Santiago")
    const fecha_local = now.toISO()
    return fecha_local
}

module.exports = {
    obtenerFechaZonaHorariaLocal,
    formatDateHoy,
    formatMes,
    formatDateMonth,
    formatDateYear,
    addMoreDays,
    restDays,
    formatDate,
    configuracion_fechas,
    configuracion_horas,
    formatDateHoyFromDate
}