const checkPermission = (name, user) => {
    if(!user) return false
    if(!user.data) return false
    if(user.data.role === "admin") return true
    if(!user.data.permisos) return false
    if(Array.isArray(user.data.permisos) !== true) return false
    if(user.data.permisos.length < 1) return false

    const i = user.data.permisos.findIndex(permiso => permiso.module === name)
    if(i < 0) return false
    return true
}

module.exports = {
    checkPermission
}