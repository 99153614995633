import React, { Component, useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown, Navbar, Container, Row, Button, Col, Table, Spinner, Card } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import { key_local_storage_cart, key_local_storage_user, urlapi } from '../../lib/backend/data'
import { logotipo } from '../../lib/global/data'
import Drawer from 'react-modern-drawer'
import Select from 'react-select';
import { obtenerFechaZonaHorariaLocal } from '../../lib/helpers/dates'
import { toast } from 'react-toastify'
import { formatoRut, validarRut } from '../../lib/helpers/helpers'
import CrearEmpresa from '../../subComponents/empresas/crear_modal'

const Header = (props) => {
    let carrito = props.carrito ? props.carrito : []
    const user = JSON.parse(localStorage.getItem(key_local_storage_user))
    const [show_drawer, setShowDrawer] = useState(false)
    const [tipoDoc, setTipoDoc] = useState(39)
    const [clientes, setClientes] = useState([
      {value: 2, label: "Cliente genérico"}
    ])
    const [clienteEspecifico, setClienteEspecifico] = useState(false)
    const [loadingBuscarCliente, setLoadingBuscarCliente] = useState(false)
    const [listaClientes, setListaClientes] = useState([])
    const mensaje_emitiendo_default = "Emitiendo DTE"
    const [loadingClientes, setLoadingClientes] = useState(false)
    const [loadingMetodosPago, setLoadingMetodosPago] = useState(false)
    const [clienteSeleccionado, setClienteSeleccionado] = useState(clientes.length > 0 ? clientes[0].value : false)
    const [metodosPago, setMetodosPago] = useState([])
    const [pagos, setPagos] = useState([])
    const [termId, setTermId] = useState(false)
    const [salesManId, setSalesManId] = useState(1)
    const [wareHouseId, setWareHouseId] = useState("001")
    const [motivo, setMotivo] = useState("")
    const [branchId, setBranchId] = useState(1)
    const [autorizado, setAutorizado] = useState(false)
    const [allow_invoice_witouth_payment, setAllowInvoiceWitouthPayment] = useState(false)
    const [iva, setIva] = useState(19)
    const [facturando, setFacturando] = useState(false)
    const [ultimoDocGenerado, setUltimoDocGenerado] = useState(localStorage.getItem("ultimo_doc_generado") ? localStorage.getItem("ultimo_doc_generado") : false)
    const [ mensajeFacturando, setMensajeFacturando ] = useState(mensaje_emitiendo_default)
    const [rut, setRut] = useState("")


    const toggleDrawer = (e) => {
        setShowDrawer(false)
    }

    const limpiarCarrito = () => {
      if(props.cleanCart){
        props.cleanCart()
      }
      setPagos(prev => [...[], ...[]])
    }

    const handleChangeCantidad = (e) => {
        const { name, value } = e.target
        const i = carrito.findIndex(p => p._id === name)
        if(i < 0) return false
        carrito[i].cantidad = value
        if(props.onChangeCarrito) props.onChangeCarrito(carrito)
        return false
    }

    const guardarUltimoDoc = (val) => {
      localStorage.setItem("ultimo_doc_generado", val)
      setUltimoDocGenerado(val)
    }

    useEffect(() => {
      obtenerCuenta()
    }, [])

    const buscarPDF = async (invoiceId, tok) => {
      setMensajeFacturando("Generando PDF")
      return fetch(`https://api.laudus.cl/sales/invoices/${invoiceId}/pdf`,{
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${tok}`
        }
    })
    .then(res => {
        if(res.status === 401) {
          setFacturando(false)
          return toast.error("NO autorizado para consultar PDF")
        }
        return res.blob()
    })
    .then(blob => {

          if(blob){
            // Leer el archivo Blob como una cadena de datos
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
              // Obtener la cadena de datos base64 del archivo
              const base64data = reader.result.split(',')[1];
              
              const pdfObject = document.createElement('object');
              pdfObject.data = 'data:application/pdf;base64,' + base64data;
              pdfObject.type = 'application/pdf';
              pdfObject.setAttribute('media', 'print');

              if(document.getElementById('pdf-container')){
                const pdfContainer = document.getElementById('pdf-container');
                pdfContainer.innerHTML = '';
                pdfContainer.appendChild(pdfObject);
                setTimeout(() => {
                  if(document.getElementById('secondaryPrint')){
                    document.getElementById('secondaryPrint').click()
                  }
                }, 4000);
              }
            };
            /*
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = `${invoiceId}.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            */
          }
          return setFacturando(false)
    })
    .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setFacturando(false)
    })
    }

    const handleChangeMotivo = (e) => {
      const { value } = e.target
      return setMotivo(value)
    }

    const campoMotivo = () => {
      if(tipoDoc.toString() !== "52") return false
      return <div className='mb-3'>
        <label className='form-control-label d-block'>Motivo</label>
        <input className='form-control' onChange={handleChangeMotivo} />
      </div>
    }

    const obtenerCuenta = async () => {
      return fetch(`${urlapi}/session/details`,{
          method:'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${user.tokenSession}`
          }
      })
      .then(res => {
          if(res.status === 401) return window.location = '/login'
          return res.json()
      })
      .then(res => {
          if(!res){
            return false
          } else if(res.errorMessage){
            return false
          } else if(res._id){
            user.data = res
            localStorage.setItem(key_local_storage_user, JSON.stringify(user))
            return res.allow_invoice_witouth_payment === true ? true : false
          }
          return false
        })
      .catch(error => false)
  }

    const facturar = async () => {
        let isGuiaDespacho = false
//        if(!termId) return toast.error("Selecciona un método de pago")
        if(carrito.length < 1) return toast.error("Agrega al carrito al menos un producto")
        const fecha = obtenerFechaZonaHorariaLocal()
        if(tipoDoc.toString() === "52") isGuiaDespacho = true

        let motivo_guia = ''
        if(isGuiaDespacho) motivo_guia = motivo

        let items = []
        let calcular_total = carrito.reduce((prev, next) => {
          let cantidad = parseInt(next.cantidad)
          if(isNaN(cantidad)) cantidad = 0
          return prev + (next.precio * cantidad)
        }, 0)

        if(calcular_total < 1) return toast.error("Monto de venta inválido")

        for( const pro of carrito ){
          if(!pro.id) return toast.error(`${pro.titulo} no está sincronizado, no posee ID`)

          const sub_total = pro.cantidad * pro.precio

          const precio_neto = (pro.precio / 1.19).toFixed(2)
          const iva = Math.round((pro.precio - precio_neto) * pro.cantidad)

          const payload_producto = {
            "product": {
              "productId": pro.id,
              "sku": pro.codigo,
              "description": pro.titulo
            },
            "quantity": pro.cantidad,
            "originalUnitPrice": precio_neto,
            "unitPrice": precio_neto,
            "VAT": iva,
            "VATRate": 19.0
          }
          items.push(payload_producto)
        }

        let payload = {
            "docType": {
              "docTypeId": parseInt(tipoDoc),
            },
            "customer": {
              "customerId": clienteSeleccionado
            },
            "warehouse": {
              "warehouseId": wareHouseId
            },
            "salesman": {
              "salesmanId": salesManId,
            },
            "branch": {
              "branchId": branchId
            },
            "nullDoc": false,
            "issuedDate": fecha,
            "dueDate": fecha,
            "payInOriginalCurrency": true,
            "purchaseOrderNumber": "",
            "deliveryCost": 0,
            "deliveryNotes": motivo_guia,
            "deliveryVehiclePlate": "",
            "bypassCreditLimit": true,
            "notes": "",
            "createAccounting": true,
            "items": items,
            pagos
          }

          
          if(tipoDoc !== 1000){
            if(pagos.length < 1) {
                if(allow_invoice_witouth_payment !== true) return toast.error("No agregaste ningún método de pago, agrega al menos un tipo de pago")
                const autorizar_pago = await obtenerCuenta()
                if(autorizar_pago !== true) return toast.error("Operación no autorizada, recarga la página para refrescar la información")
              } else {
                const faltantes = pagos.filter(p => typeof p.tipo === "undefined" || typeof p.monto === "undefined" || !p.tipo ).length
                if(faltantes > 0) return toast.error("Debes configurar todos los pagos")
                const invalidos = pagos.filter(p => {
                  if(isNaN(parseInt(p.monto))) return true
                }).length
                if(invalidos > 0) return toast.error("Debes configurar el monto de todos los pagos")
                const total_sumado = pagos.reduce((prev,next) => prev + next.monto, 0)
                if(total_sumado !== calcular_total ) return toast.error("Monto de pagos no coincide con el monto de venta")  
              }
            }

            if(isGuiaDespacho === true){
            payload.deliverySIIType = "1"
            payload.isSale = true
          }
          setMensajeFacturando(mensaje_emitiendo_default)
          setFacturando(true)
          return fetch(`${urlapi}/invoice`,{
            method:'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setFacturando(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setFacturando(false)
            } else if(res.salesInvoiceId){
                toast.success(`Emitido el documento ${res.salesInvoiceId}`)
                guardarUltimoDoc(res.salesInvoiceId)
                limpiarCarrito()
                return buscarPDF(res.salesInvoiceId, res.token)
            } else if(res.salesWaybillId){
                toast.success(`Emitido el documento ${res.salesWaybillId}`)
                guardarUltimoDoc(res.salesWaybillId)
                limpiarCarrito()
            } else if(res._id){ 
                toast.success(`Emitido el pedido ${res.id}`)
                guardarUltimoDoc(res.id)
                limpiarCarrito()
          }
            return setFacturando(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setFacturando(false)
        })
    }

    const removerCarrito = (pos) => {
        if(props.onProductRemoved){
            props.onProductRemoved(pos)
        }
        return false
    }

    const buscarCliente = () => {
      if(!validarRut(rut)) return toast.error("Rut inválido")
      const dato = formatoRut(rut)
      setLoadingBuscarCliente(true)
      setClienteSeleccionado(false)
      return fetch(`${urlapi}/clients/list`,{
        method:'POST',
        body: JSON.stringify({
          rut: dato
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${user.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => {
        console.log(res)
        if(!res){
            toast.error('Sin datos')
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
        } else if(Array.isArray(res) !== false){
            if(res.length < 1) toast.warn("Sin resultados encontrados")
            setListaClientes(res)
        }
        return setLoadingBuscarCliente(false)
    })
    .catch(error => {
        toast.error("Error al consultar métodos de pago, intente nuevamente")
        return setLoadingBuscarCliente(false)
    })
    }

    const handleChangeTerm = (e) => {
      const { value } = e.target
      return setTermId(value)
    }
    
    const handleChangeRut = (e) => {
      const { value } = e.target
      return setRut(value)
    }
    
    const handleChangeSalesMan = (e) => {
      const { value } = e.target
      return setSalesManId(value)
    }

    const seleccionarCliente = (client) => {
        setClienteSeleccionado(client.customerId)
    }

    const mostrarFormularioCliente = () => {

      if(!clienteEspecifico) return false

      return <Row>
          <Col className='mb-3' xs={6}>
          <input className='form-control' placeholder='BUSCAR RUT' onKeyUp={handleChangeRut} />
          </Col>
          <Col className='mb-3' xs={6}>
              {
                loadingBuscarCliente ? <Spinner animation='border' /> : <Button variant="success" className='mb-1' onClick={() => buscarCliente()} >BUSCAR CLIENTE</Button>
              }
              <CrearEmpresa onCreatedNewCliente={(data) => {
                setListaClientes([data])
                setClienteSeleccionado(data.customerId)
              }} />
          </Col>
          <Col xs={12}>
          {
            listaClientes.length > 0 ? <div>
              <Table>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Rut</th>
                  </tr>
                </thead>
                <tbody>
                {
                  listaClientes.map(cliente => {
                    return <tr key={`cliente-${cliente.customerId}`} className="hover" onClick={() => seleccionarCliente(cliente)}>
                      <th>
                        <p className='mb-0'>{clienteSeleccionado===cliente.customerId ? <i className="text-success fas fa-check-square"></i> : <i className="far fa-square"></i>} {cliente.name}</p>
                      </th>
                      <th><p className='mb-0'>{cliente.vatId}</p></th>
                    </tr>
                  })
                }
                </tbody>
              </Table>
            </div> : <div>
              {
                rut ? <div>
                  <Button variant="success">CREAR NUEVO</Button>
                </div> : false
              }
            </div>
          }
          </Col>
        </Row>

      return false
    }
    
    const handleChangeWareHouse = (e) => {
      const { value } = e.target
      return setWareHouseId(value)
    }

    const switchClienteEspecifico = () => {
      const valor = clienteEspecifico === true ? false : true
      setClienteEspecifico(valor)
    }
    
    const handleChangeBranchId = (e) => {
      const { value } = e.target
      return setBranchId(value)
    }

    const handleChangeTipoDoc = (e) => {
      const { value } = e.target
      return setTipoDoc(value)
    }

    const agregarPago = () => {
      if(allow_invoice_witouth_payment === true) return toast.error("Seleccionaste EMITIR SIN PAGO, desactivalo para poder añadir pagos")
      pagos.unshift({ tipo: "", monto: 0 })
      return setPagos(prev => [...[], ...pagos])
    }

    const maximo = (pos, monto_actual) => {
      let calcular_total = carrito.reduce((prev, next) => {
            let cantidad = parseInt(next.cantidad)
            if(isNaN(cantidad)) cantidad = 0
            return prev + (next.precio * cantidad)
        }, 0)

        let total_sumado = pagos.reduce((prev,next) => {
          let monto_pago = next.monto
          if(isNaN(parseInt(monto_pago))) monto_pago = 0
          return prev + monto_pago
        }, 0)

        let total_monto_actual = 0
        if(!isNaN(parseInt(monto_actual))) total_monto_actual = parseInt(monto_actual)
//        total_sumado = total_sumado + total_monto_actual

        console.log({total_sumado,calcular_total})
        if(total_sumado > calcular_total){
          return toast.error("El total sumado de los pagos es mayor al total del carrito")
        } else if (total_sumado === calcular_total){
          return false
        }

        const restante = calcular_total - total_sumado + total_monto_actual
        pagos[pos].monto = restante

        return setPagos(prev => [...[], ...pagos])

    }

    const handleChangeAmount = (e) => {
      const { value } = e.target
      const pos = parseInt(e.target.getAttribute('pos'))
      pagos[pos].monto = parseInt(value)
      return setPagos(prev => [...[], ...pagos])
    }

    const handleChangePago = (e) => {
      const { value } = e.target
      const pos = parseInt(e.target.getAttribute('pos'))
      pagos[pos].tipo = value
      return setPagos(prev => [...[], ...pagos])
    }

    const eliminar = (pos) => {
      pagos.splice(pos, 1)
      return setPagos(prev => [...[], ...pagos])
    }

    const accionesMetodoPago = () => {
      if(!autorizado) return false
      return <Button className='ml-3' size="sm" variant="outline-primary" onClick={() => {
          setPagos(prev => [...[], ...[]])
          const nuevo_valor = allow_invoice_witouth_payment === true ? false : true
          return setAllowInvoiceWitouthPayment(nuevo_valor)
        }}>{allow_invoice_witouth_payment === true ? <i className="text-success fas fa-check-circle"></i> : false } EMITIR SIN PAGO</Button>
    }

    const mostrarEstadoAutorizacionPago = () => {
      if(autorizado === true) return <div className='mt-3'>
        <Card bg='warning' className='p-2'>
          <h6 className='mb-0'><i className="fas fa-exclamation-triangle"></i> ESTÁS AUTORIZADO PARA EMITIR DOCUMENTOS SIN PAGOS</h6>
        </Card>
      </div>
    }

    const mostrarPagos = () => {

      return <div>
        <Button size="sm" variant='outline-primary' onClick={() => agregarPago()}>AGREGAR MÉTODO DE PAGO</Button>
        {accionesMetodoPago()}
        {mostrarEstadoAutorizacionPago()}
        {
          pagos.map((pago,ip) => {
            return <div key={`e-${ip+1}`}>
              <Row>
                <Col >
                  <label className='form-control-label'> Tipo de pago </label>
                  <select className='form-control' value={pago.tipo} pos={ip} onChange={handleChangePago}>
                    <option value="">Seleccione</option>
                    <option value="E">Efectivo</option>
                    <option value="I">Depósito en C/C - Transferencia</option>
                    <option value="T">Tarjeta de crédito</option>
                    <option value="D">Tarjeta de débito</option>
                  </select>
                  <h6 className='mb-2 mt-2 hover text-danger' onClick={() => eliminar(ip)}><i className="fas  fa-trash"></i> Remover</h6>
                </Col>
                <Col>
                <label className='form-control-label'>Monto</label>
                <input className='form-control' pos={ip} value={pago.monto} type="number" onChange={handleChangeAmount} />
                <p className='mb-0 hover' style={{ position: "absolute", right: "30px", marginTop: -32 }} onClick={() => maximo(ip, pago.monto)}><b>MAX</b></p>
                </Col>
              </Row>
            </div>
          })
        }
      </div>
    }

    const mostrarMetodosPago = () => {
      return <div>
        {mostrarPagos()}
      </div>
    }

    const mostrarCarrito = () => {

        let calcular_total = carrito.reduce((prev, next) => {
            let cantidad = parseInt(next.cantidad)
            if(isNaN(cantidad)) cantidad = 0
            return prev + (next.precio * cantidad)
        }, 0)

        return <div >
          { typeof ultimoDocGenerado !== "undefined" ? <Card className="p-3 mb-3 text-light" bg="dark"><h6 className='mb-0'>Último documento generado {ultimoDocGenerado}</h6></Card> : false }
            <Row className='mb-3'>
                <Col xs={12}>
                <Row>
                    <Col className='mb-3'>
                        <label className='form-control-label'>Sucursal</label>
                        <select className='form-control' onChange={handleChangeBranchId}>
                            <option value={1}>10 de Julio</option>
                            <option value={2}>San Isidro</option>
                        </select>
                    </Col>
                    <Col className='mb-3'>
                        <label className='form-control-label'>Bodega</label>
                        <select className='form-control' onChange={handleChangeWareHouse}>
                            <option value="001">Bodega Principal</option>
                            <option value="002">Bodega 10 de Julio</option>
                            <option value="003">Bodega San Isidro</option>
                        </select>
                    </Col>
                    <Col className='mb-3' >
                        <label className='form-control-label'>Vendedor</label>
                        <select className='form-control' onChange={handleChangeSalesMan}>
                            <option value={1}>abdias</option>
                        </select>
                    </Col>
                    <Col className='mb-3'>
                        <label className='form-control-label'>Tipo de documento</label>
                        <select className='form-control' onChange={handleChangeTipoDoc} value={tipoDoc}>
                            <option value={39}>Boleta electrónica</option>
                            <option value={33}>Factura electrónica</option>
                            <option value={52}>Guia de despacho electrónica</option>
                            <option value={1000}>Pedido para picking</option>
                        </select>
                    </Col>
                    <Col className='mb-3' xs={12}>
                        {mostrarMetodosPago()}
                    </Col>
                    <Col className='mb-0' xs={12}>
                        <label className='form-control-label'>Cliente <b className='text-primary hover' onClick={() => switchClienteEspecifico()} >{clienteEspecifico === true ? "Seleccionar cliente genérico" : "Seleccionar cliente específico"}</b></label>
                    </Col>
                    <Col md={12}>{campoMotivo()}</Col>
                    <Col md={8}>
                      {mostrarFormularioCliente()}
                    </Col>
                    
                </Row>
                </Col>
                <Col xs={12}>
                    <div>
                      { facturando ? <div><Spinner animation='border' /><h5>{mensajeFacturando}</h5></div> : <Button style={{ width: '100%' }} onClick={() => facturar()} >FACTURAR ${Intl.NumberFormat("es-ES").format(calcular_total)}</Button> }
                    </div>
                    <div id="pdf-container" style={{ width: "100%" }}></div>
                </Col>
            </Row>
                        <Table bordered>
                            <thead>
                                <tr>
                                <th>Código</th>
                                <th>Producto</th>
                                <th>Cantidad</th>
                                <th>Precio Un.</th>
                                <th>Sub total</th>
                                <th></th>
                                </tr>
                            </thead>
                        {
                            carrito.map((pro,pos) => {

                                const cantidad = isNaN(parseInt(pro.cantidad)) ? 0 : parseInt(pro.cantidad)

                                return <tr key={pro._id}>
                                        <td><h6>{pro.codigo}</h6></td>
                                        <td>{pro.titulo}</td>
                                        <td><input className='form-control' value={pro.cantidad} type="number" name={pro._id} onChange={handleChangeCantidad} /></td>
                                        <td><h5>${pro.precio}</h5></td>
                                        <td><h5>${Intl.NumberFormat("es-ES").format(cantidad * pro.precio)}</h5></td>
                                        <td><h5 className='hover text-danger' onClick={() => removerCarrito(pos)}><i className="fas fa-trash"></i></h5></td>
                                    </tr>
                            })
                        }
                        </Table>
        </div>
    }

    const checkPermission = (name) => {
      if(!user) return false
      if(!user.data) return false
      if(user.data.role === "admin") return true
      if(!user.data.permisos) return false
      if(Array.isArray(user.data.permisos) !== true) return false
      if(user.data.permisos.length < 1) return false

      const i = user.data.permisos.findIndex(permiso => permiso.module === name)
      if(i < 0) return false
      return true
    }

    return <div>
        <Navbar bg="light" expand="lg" className='shadow' >
            <Container fluid>
                <Navbar.Brand className='mr-3'><img alt="logo" id="logo-principal" src={logotipo}  /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav >
                <Link to="/" className="nav-link">Inicio</Link>
                { checkPermission('usuarios') === true ? <Link to="/usuarios" className="nav-link">Usuarios</Link>  : false }
                { checkPermission('pedidos') === true ? <Link to="/pedidos" className="nav-link">Pedidos</Link>  : false }
                </Nav>  
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                <Nav>
                    <h4 className='mb-0 hover' onClick={() => {
                      // setShowDrawer(true)
                      }}>BILINGUAL 1.3</h4>
                </Nav>
                    <Dropdown>
                    <Dropdown.Toggle variant="white" id="dropdown-basic">
                    <i className="fas fa-user-circle"></i> {user.data.nombres}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="1"><Link to="/account" className="nav-link">Mi cuenta</Link></Dropdown.Item>
                        <Dropdown.Item eventKey="1"><Link to="/login" className="text-danger"><i class="fas fa-power-off"></i> Salir</Link></Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
                    </Navbar.Collapse>
            </Container>
        </Navbar>
        <Drawer
        open={show_drawer}
        onClose={()=>toggleDrawer()}
        direction='right'
        size={"70%"}
        className='drawer-carrito'
        >
        <div className='p-3' style={{ maxHeight: "100%", overflow: "scroll"}}>
           <h3>{carrito.length} productos</h3>
            {mostrarCarrito()}
        </div>
    </Drawer>
        </div>
}

export default Header