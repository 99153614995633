import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Breadcrumb } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays } from '../../lib/helpers/dates';
import Select from 'react-select';
import { addDays } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import ModelosBuscador from '../../subComponents/modelos/buscador';
import CilindradaBuscador from '../../subComponents/cilindradas/buscador';
import { Link } from 'react-router-dom';

const CrearProducto = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ ruta, setRuta ] = useState({})
    const [ aplicaciones, setAplicaciones ] = useState([])
    const [ conductores, setConductores ] = useState([])
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ loadingRuta, setLoadingRuta ] = useState(true)
    const [ conductor, setConductor ] = useState(false)
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ descripcion, setDescripcion ] = useState('')
    const [ showButtonUpdateDate, setShowButtonUpdateDate ] = useState(false)
    const [ loadingAplicacionId, setAplicacionId ] = useState(false)
    const token = props.token ? props.token : false
    const idproducto = props.idproducto ? props.idproducto : false

    const actualizarProducto = async () => {
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/productos`,{
            method:'POST',
            body: JSON.stringify(ruta),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Guardada exitosamente')
                return setTimeout(() => {
                    window.location = `/product-edit-${res._id}`
                }, 1000);
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCreacion(false)
        })
    }

    const handleChangeProducto = (e) => {
        const { name, value } = e.target
        ruta[name] = value
        return setRuta(ruta)
    }

    const cambiarRangoFecha = (item) => {
        setShowButtonUpdateDate(true)
        return setSelectionRange(item.selection)
    }

    const handleChangeSelectConductor = (e) => {
        return setConductor(e.value)
    }

    const confirmarCambioFecha = async () => {
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/rutas/fecha`,{
            method:'PUT',
            body: JSON.stringify({
                id: ruta._id,
                fecha_min_entrega: selectionRange.startDate,
                fecha_max_entrega: selectionRange.endDate,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Actualizado exitosamente')
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingCreacion(false)
        })
    }

    const solicitarCambioFecha = (id) => {
        return confirmAlert({
            title: `¿Cambio de fechas?`,
            message: `Actualizar el rango de entrega modificará individualmente cada orden siempre y cuando éstas no estén finalizadas.`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarCambioFecha()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const mostrarBotonActualizarFecha = () => {
        if(!showButtonUpdateDate) return false
        return <Button size="sm" className='w-100' onClick={()=>solicitarCambioFecha()} >ACTUALIZAR RANGO DE ENTREGA</Button>
    }

    const confirmarEliminadoRuta = () => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/rutas?id=${ruta._id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = '/rutas'
                }, 1000);
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingRuta(false)
        })
    }

    const solicitarEliminarRuta = () => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar esta ruta y todas las órdenes asociadas`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminadoRuta()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const guardarCambios = (id, posicion) => {
        let actualizar = false
        let crear = 0
        if(id){
            const i = aplicaciones.findIndex(a => a._id === id)
            if(i < 0) return false
            actualizar = aplicaciones[i]
        } else {
            crear++
            actualizar = aplicaciones[posicion]
        }
        if(!actualizar.id_cilindrada) return toast.error("Cilindrada es requerido")
        if(!actualizar.id_modelo) return toast.error("Modelo es requerido")
        setAplicacionId(id)
        return fetch(`${data.urlapi}/aplicaciones`, {
            method:'PUT',
            body: JSON.stringify(actualizar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Guardado exitosamente")
                if(crear > 0){
                    aplicaciones[posicion]._id = res._id
                    setAplicaciones(prev => [...[], ...aplicaciones])
                }
            }
            return setAplicacionId(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setAplicacionId(false)
        })
    }


    return <div>
        <Breadcrumb>
        <Breadcrumb.Item href="#"><Link to="/productos">Productos</Link> </Breadcrumb.Item>
        <Breadcrumb.Item active>Crear</Breadcrumb.Item>
        </Breadcrumb>
        <hr />
    <Row>
        <Col md={12}>
            <Row>
            <Col md={6}><h4>Crear producto</h4></Col>
            </Row>
        </Col>
        <Col md={3}>
            <label className='form-control-label d-block'>Título</label>
            <input className='form-control mb-3' name="titulo" defaultValue={ruta.titulo} onChange={handleChangeProducto} />
        </Col>
        <Col md={3}>
            <label className='form-control-label d-block'>Código</label>
            <input className='form-control mb-3' name="codigo" defaultValue={ruta.codigo} onChange={handleChangeProducto} />
        </Col>
        <Col md={3}>
            <label className='form-control-label d-block'>Cantidad</label>
            <input className='form-control mb-3' type="number" name="stock" defaultValue={ruta.stock} onChange={handleChangeProducto} />
        </Col>
        <Col md={2}>
            <label className='form-control-label d-block'>Código proveedor</label>
            <input className='form-control mb-3' name="codigo_proveedor" defaultValue={ruta.codigo_proveedor} onChange={handleChangeProducto} />
        </Col>
        <Col md={2}>
            <label className='form-control-label d-block'>Código fábrica</label>
            <input className='form-control mb-3' name="codigo_fabrica" defaultValue={ruta.codigo_fabrica} onChange={handleChangeProducto} />
        </Col>
        <Col md={2}>
            <label className='form-control-label d-block'>Código importación</label>
            <input className='form-control mb-3' name="codigo_importacion" defaultValue={ruta.codigo_importacion} onChange={handleChangeProducto} />
        </Col>
        <Col md={3}>
            <label className='form-control-label d-block'>Precio</label>
            <input className='form-control mb-3' name="precio" type="number" defaultValue={ruta.precio} onChange={handleChangeProducto} />
        </Col>
        <Col md={12} className="mb-3">
            { loadingCreacion === true ? <Spinner animation='border' /> : <Button size="sm" variant="success" onClick={()=>actualizarProducto()}>CREAR</Button> }
        </Col>
    </Row>
</div>
}

export default CrearProducto