import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Breadcrumb } from 'react-bootstrap'
import data, { urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays } from '../../lib/helpers/dates';
import Select from 'react-select';
import { addDays } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import ModelosBuscador from '../../subComponents/modelos/buscador';
import CilindradaBuscador from '../../subComponents/cilindradas/buscador';
import { Link } from 'react-router-dom';
import ModelosSelector from '../../subComponents/modelos/selector';
import CilindradaSelector from '../../subComponents/cilindradas/selector';
import Inventario from '../../subComponents/repuestos/inventario';
import TraspasoAplicaciones from './traspaso_aplicaciones';
import Etiqueta from '../../subComponents/productos/etiqueta';

const EditarProducto = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ ruta, setRuta ] = useState({})
    const [ aplicaciones, setAplicaciones ] = useState([])
    const [ conductores, setConductores ] = useState([])
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ loadingRuta, setLoadingRuta ] = useState(true)
    const [ conductor, setConductor ] = useState(false)
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ modelosMultiples, setModelosMultiple ] = useState([])
    const [ descripcion, setDescripcion ] = useState('')
    const [ showButtonUpdateDate, setShowButtonUpdateDate ] = useState(false)
    const [ loadingAplicacionId, setAplicacionId ] = useState(false)
    const [ loadingCopiado, setLoadingCopiado ] = useState(false)
    const token = props.token ? props.token : false
    const idproducto = props.idproducto ? props.idproducto : false

    useEffect(() => {
        obtenerProducto(idproducto)
    },[])

    const obtenerProducto = async (id) => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/productos?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(res._id){
                setRuta(res)
                if(res.aplicaciones){
                    if(Array.isArray(res.aplicaciones) !== false){
                        setAplicaciones(res.aplicaciones)
                    }
                }
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const obtenerProveedores = async () => {
        setLoadingConductores(true)
        return fetch(`${data.urlapi}/conductores/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(Array.isArray(res) !== false){
                setConductores(res.map(c => ({ value: c._id, label: `${c.patente.toUpperCase()} · ${c.modelo.toUpperCase()}` })))
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const actualizarProducto = async () => {
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/productos`,{
            method:'PUT',
            body: JSON.stringify(ruta),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Guardada exitosamente')
                return setTimeout(() => {
                    window.location = `/product-edit-${idproducto}`
                }, 1000);
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCreacion(false)
        })
    }

    const handleChangeProducto = (e) => {
        const { name, value } = e.target
        ruta[name] = value
        return setRuta(ruta)
    }

    const cambiarRangoFecha = (item) => {
        setShowButtonUpdateDate(true)
        return setSelectionRange(item.selection)
    }

    const handleChangeSelectConductor = (e) => {
        return setConductor(e.value)
    }

    const confirmarCambioFecha = async () => {
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/rutas/fecha`,{
            method:'PUT',
            body: JSON.stringify({
                id: ruta._id,
                fecha_min_entrega: selectionRange.startDate,
                fecha_max_entrega: selectionRange.endDate,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Actualizado exitosamente')
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingCreacion(false)
        })
    }

    const solicitarCambioFecha = (id) => {
        return confirmAlert({
            title: `¿Cambio de fechas?`,
            message: `Actualizar el rango de entrega modificará individualmente cada orden siempre y cuando éstas no estén finalizadas.`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarCambioFecha()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const mostrarBotonActualizarFecha = () => {
        if(!showButtonUpdateDate) return false
        return <Button size="sm" className='w-100' onClick={()=>solicitarCambioFecha()} >ACTUALIZAR RANGO DE ENTREGA</Button>
    }

    const confirmarEliminadoRuta = () => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/productos?id=${ruta._id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = '/productos'
                }, 1000);
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingRuta(false)
        })
    }

    const solicitarEliminarRuta = () => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar esta ruta y todas las órdenes asociadas`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminadoRuta()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const guardarCambios = (id, posicion) => {
        let actualizar = false
        let crear = 0
        if(id){
            const i = aplicaciones.findIndex(a => a._id === id)
            if(i < 0) return false
            actualizar = aplicaciones[i]
        } else {
            crear++
            actualizar = aplicaciones[posicion]
        }
        if(!actualizar.id_cilindrada) return toast.error("Cilindrada es requerido")
        if(!actualizar.id_modelo) return toast.error("Modelo es requerido")
        setAplicacionId(id)
        return fetch(`${data.urlapi}/aplicaciones`, {
            method:'PUT',
            body: JSON.stringify(actualizar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Guardado exitosamente")
                if(crear > 0){
                    aplicaciones[posicion]._id = res._id
                    setAplicaciones(prev => [...[], ...aplicaciones])
                }
            }
            return setAplicacionId(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setAplicacionId(false)
        })
    }

    const handleSelectModelo = (data, id) => {
        console.log({ data })
        const i = aplicaciones.findIndex(a => a._id === id)
        if(i < 0) return false
        aplicaciones[i].id_modelo = data._id
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const handleSelectCilindrada = (data, id) => {
        const i = aplicaciones.findIndex(a => a._id === id)
        if(i < 0) return false
        aplicaciones[i].id_cilindrada = data._id
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const handleChangeAplicacion = (e) => {
        const { name, value } = e.target
        const idapp = e.target.getAttribute("idapp")
        const pos = e.target.getAttribute("pos")
        if(idapp){
            const i = aplicaciones.findIndex(a => a._id === idapp)
            aplicaciones[i][name] = value
        } else {
            aplicaciones[pos][name] = value
        }
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const solicitarEliminarApp = (id, pos) => {
        if(!id) return eliminarApp(id,pos)
        return confirmAlert({
            title: 'Confirmar',
            message: `Estás a punto de eliminar esta aplicación, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => eliminarApp(id,pos)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    const eliminarApp = async (id,pos) => {
        if(!id) {
            aplicaciones.splice(pos,1)
            return setAplicaciones(prev => [...[], ...aplicaciones])
        }
        setAplicacionId(id)
        return fetch(`${data.urlapi}/productos/aplicaciones?id=${id}`, {
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Guardado exitosamente")
                const i = aplicaciones.findIndex(a => a._id === id)
                if(i > -1){
                    aplicaciones.splice(i,1)
                    setAplicaciones(prev => [...[], ...aplicaciones])
                }
            }
            return setAplicacionId(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setAplicacionId(false)
        })
    }

    const agregarAplicacion = () => {
        aplicaciones.unshift({ id_producto: ruta._id })
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const handleSelectModeloMulti = (data) => {
        setModelosMultiple(data)
    }

    const agregarAplicaciones = () => {
        if(modelosMultiples.length < 1) return toast.error("Selecciona al menos un modelo")
        for( const model of modelosMultiples ){
            aplicaciones.unshift({ id_producto: ruta._id, id_modelo: model.value })
        }
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const onSelectProduct = (e) => {
        const { _id } = e
        setLoadingCopiado(true)
        return fetch(`${urlapi}/productos/copiar-aplicaciones`,{
            method:'POST',
            body: JSON.stringify({
                id_producto_to: _id,
                id_producto_from: ruta._id
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCopiado(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCopiado(false)
            } else if(res._id){
                toast.success('Guardada exitosamente')
            }
            return setLoadingCopiado(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCopiado(false)
        }) 
    }

    const verAplicaciones = () => {
        return <div>
            <Button variant="outline-primary" size="sm" onClick={() => agregarAplicacion()}>CREAR NUEVA</Button>
            <hr />
            <Row>
                <Col md={6}>
                    <ModelosSelector token={token} isMulti={true} handleSelectModeloMulti={(data) => handleSelectModeloMulti(data)} />
                </Col>
                <Col md={4}>
                    <label className='form-control-label d-block'>Acción masiva</label>
                    <Button variant='outline-primary' onClick={() => agregarAplicaciones()} >AGREGAR APLICACIONES DESDE ESTOS MODELOS</Button>
                </Col>
                <Col md={2}>
                    { loadingCopiado ? <div>
                        <Spinner animation='border' />
                        <h5>Espere un momento</h5>
                    </div> : <TraspasoAplicaciones onSelectProduct={(e) => onSelectProduct(e)} token={token} /> }
                </Col>
            </Row>
            <hr />
            {
                aplicaciones.map((app,iapp) => {
                    return <Row key={app._id} className="mb-3">
                        <Col md={2}>
                            <ModelosSelector token={token} default_value={app.id_modelo} handleSelectModelo={(data) => handleSelectModelo(data, app._id)} />
                        </Col>
                        <Col md={2}>
                            <CilindradaSelector token={token} default_value={app.id_cilindrada} handleSelectModelo={(data) => handleSelectCilindrada(data, app._id)} />
                        </Col>
                        <Col md={2}>
                            <label className='form-control-label d-block'>Año desde</label>
                            <input className='form-control' pos={iapp} type="number" name="ano_desde" idapp={app._id} defaultValue={app.ano_desde} onChange={handleChangeAplicacion} />
                        </Col>
                        <Col md={2}>
                            <label className='form-control-label d-block'>Año hasta</label>
                            <input className='form-control' pos={iapp} type="number" name="ano_hasta" idapp={app._id} defaultValue={app.ano_hasta} onChange={handleChangeAplicacion} />
                        </Col>
                        <Col md={2}>
                            <label className='form-control-label d-block'>Click para guardar</label>
                            { loadingAplicacionId === app._id ? <Spinner animation='border' /> : <Button variant='success' onClick={() => guardarCambios(app._id, iapp)} >GUARDAR</Button> }
                        </Col>
                        <Col md={2}>
                            <label className='form-control-label d-block'>Click para eliminar</label>
                            { loadingAplicacionId === app._id ? <Spinner animation='border' /> : <Button variant='danger' onClick={() => solicitarEliminarApp(app._id, iapp)} >ELIMINAR</Button> }
                        </Col>
                    </Row>
                })
            }
        </div>
    }

    if(loadingRuta === true) return <Spinner animation='border' />
    if(Object.keys(ruta).length < 1) return <Row className="justify-content-md-center">
    <Col md={6} className="text-center">
      <img src="images/pedidos.png" style={{ width: "50%" }} />
    <h4>No hay datos suficientes para visualizar esta sección</h4>
    </Col>
    </Row>
    const i = conductores.findIndex(c => c.value === ruta.id_conductor)
    let default_vehiculo = false
    if(i > -1) default_vehiculo = conductores[i]

    return <div>
        <Breadcrumb>
        <Breadcrumb.Item href="#"><Link to="/productos">Productos</Link> </Breadcrumb.Item>
        <Breadcrumb.Item active>Editar</Breadcrumb.Item>
        </Breadcrumb>
        <hr />
    <Row>
        <Col md={12}>
            <Row>
            <Col md={6}>
                <h4>Editar producto</h4>
                {ruta.id ? <h6 className='text-success mb-3'>`Producto sincronizado con Laudus con ID {ruta.id}`</h6> : false }
            </Col>
            <Col md={6} className="text-right"><Button size="sm" variant="link" className='p-0 text-danger' onClick={()=>solicitarEliminarRuta()}>ELIMINAR</Button></Col>
            </Row>
        </Col>
        <Col md={3}>
            <label className='form-control-label d-block'>Título</label>
            <input className='form-control mb-3' name="titulo" defaultValue={ruta.titulo} onChange={handleChangeProducto} />
        </Col>
        <Col md={3}>
            <label className='form-control-label d-block'>Cantidad</label>
            <input className='form-control mb-3' type="number" name="stock" defaultValue={ruta.stock} onChange={handleChangeProducto} />
        </Col>
        <Col md={3}>
            <label className='form-control-label d-block'>Código</label>
            <input className='form-control mb-3' name="codigo"  defaultValue={ruta.codigo} onChange={handleChangeProducto} />
        </Col>
        <Col md={3}>
            <label className='form-control-label d-block'>Código fábrica</label>
            <input className='form-control mb-3' name="codigo_fabrica" autoFocus defaultValue={ruta.codigo_fabrica} onChange={handleChangeProducto} />
        </Col>
        <Col md={3}>
            <label className='form-control-label d-block'>Precio</label>
            <input className='form-control mb-3' name="precio" type="number" defaultValue={ruta.precio} onChange={handleChangeProducto} />
        </Col>
        <Col md={12}>
            <Inventario id={ruta.id} />
        </Col>
        <Col md={12} className="mb-3">
            { loadingCreacion === true ? <Spinner animation='border' /> : <Button size="sm" variant="success" onClick={()=>actualizarProducto()}>GUARDAR CAMBIOS</Button> }
        </Col>
        <Col md={5} className="mb-3">
            <Card className='p-3'>
                <Etiqueta etiquetas={[ruta]} />
            </Card>
        </Col>
        <Col md={12}>
            <Card className='p-3'>
                <h4>Aplicaciones</h4>
                {verAplicaciones()}
            </Card>
        </Col>
    </Row>
</div>
}

export default EditarProducto