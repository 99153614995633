import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Breadcrumb } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';

class EmpresasCrear extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            empresa: {
                activo: true
            },
            loadingEmpresa: false,
            comunas_descartar_creacion: [],
            creandoEmpresa: false
        }
        this.handleChangeEmpresa = this.handleChangeEmpresa.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
    }

    handleChangeEmpresa(e){
        const { empresa } = this.state
        const { name, value } = e.target
        empresa[name] = value
        return this.setState({ empresa })
    }

    handleChangeChecked(checked){
        return this.setState({ show_password: checked })
    }

    crearEmpresa(){
        const { empresa, user } = this.state
        const requeridos = [
            { value:'razon_social', label: 'Razón social' },
            { value:'nombre_fantasia', label: 'Nombre fantasía' },
            { value:'id_fiscal', label: 'ID Fiscal' }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!empresa[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
//        if(validateEmail(empresa.email) !== true) return toast.error('Email inválido')
        const loading = 'creandoEmpresa'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/empresas`,{
            method:'POST',
            body: JSON.stringify( empresa ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(res._id){ 
                toast.success('Guardado exitosamente')
                setTimeout(() => {
                    return window.location = '/empresas'
                }, 500);
            } else {
                toast.error('Ocurrió un error inesperado')
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    formularioEmpresa(){
        const { creandoEmpresa } = this.state

        return <div>
            <Row>
                <Col md={3} className="mb-3">
                    <label className='form-control-label'>Razón social</label>
                    <input name="razon_social" className='form-control' onChange={this.handleChangeEmpresa} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label'>Nombre fantasía</label>
                    <input name="nombre_fantasia" className='form-control' onChange={this.handleChangeEmpresa} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label'>ID Fiscal</label>
                    <input name="id_fiscal" className='form-control' onChange={this.handleChangeEmpresa} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label'>Dirección principal</label>
                    <input name="direccion_principal" className='form-control' onChange={this.handleChangeEmpresa} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label'>Teléfono</label>
                    <input name="telefono_principal" className='form-control' onChange={this.handleChangeEmpresa} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label'>País</label>
                    <select name="sexo" className='form-control' onChange={this.handleChangeEmpresa} defaultValue={'CL'}>
                        <option value="">Selecciona</option>
                        <option value="CL">Chile</option>
                    </select>
                </Col>
                <Col md={12}>
                    {
                        creandoEmpresa===true ? <Spinner animation='border' /> : <Button size="sm" variant='success' onClick={()=>this.crearEmpresa()}>CREAR </Button>
                    }
                </Col>
            </Row>
            
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-top pb-2 mb-3">
      </div>
    <Breadcrumb>
        <Breadcrumb.Item href="#"><Link to="/empresas">Empresas</Link> </Breadcrumb.Item>
        <Breadcrumb.Item active>Crear</Breadcrumb.Item>
    </Breadcrumb>
    <h4>Crear empresa </h4>
      <Card >
          <Card.Body>
          {this.formularioEmpresa()}
          </Card.Body>
      </Card>
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(EmpresasCrear);