import React, { useState, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useReactToPrint } from 'react-to-print';
const Barcode = require('react-barcode');

const MostrarEtiquetas = React.forwardRef((props, ref) => {
  if(!props.etiquetas) return false
  if(props.etiquetas.length < 1) return false
  return <div ref={ref} id="imprimir-v2">
  {
    props.etiquetas.map((etiqueta,i) => {
      return <div className="p-2 etiquetabodega" key={`etiqueta-${etiqueta._id}`} style={{ width: '100%' }}>
          <div style={{ textAlign: "center" }}>
            <img src="/images/logo-mesina.png" style={{ width: '50%', marginTop: 25 }} />
          </div>
          <div style={{ textAlign: "center" }}>
            <h3 style={{ margin:0, fontWeight: "bold" }}>{etiqueta.titulo ? etiqueta.titulo.toUpperCase() : 'SIN INFORMACIÓN DEL TÍTULO'}</h3>
            <Barcode fontSize={40} font="Poppins,sans-serif" width={5} style={{ width: "100%" }} value={etiqueta.codigo} />
          </div>
        { i < props.etiquetas.length -1 ? <div className="page-break"></div> : false }
      </div>
    })
  }
</div>
})

const Etiqueta = (props) => {
  const etiquetas = props.etiquetas
  const componentRef = useRef()
  const pageStyle = `
  @media print {
    .page-break {
      page-break-before: always;
    }
    img {
      width: 60% !important;
    }
    h3 {
      font-size: 40px !important;
    }
    svg {
      width: 100%;
    }
    .etiquetabodega {
      width: 100%
    }
  }  
`;
  const pageStylev2 = `
  @media print {
    body {
      padding:0px !important;
      maring:0px !important;
    }
    img {
      display:none !important;
    }
    svg {
      display:none !important;
    }
    .page-break {
      page-break-before: always;
    }
    h3 {
      font-size: 40px !important;
    }
    svg {
      width: 100%;
    }
    .etiquetabodega {
      width: 100%
    }
  }  
`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle
  })
  
  const handlePrintv2 = useReactToPrint({
    content: () => componentRef.current,
    pageStylev2
  })

  const imprimirPedidoActual = (div) => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write('<html><head><title>' + document.title  + '</title><meta name="viewport" content="width=device-width, initial-scale=1.0">');
    mywindow.document.write('<style>.etiquetabodega { max-width: 90%; margin: 0 auto; } @page { margin: 0;}body{ margin:0px;padding:0px;width:100%; max-width:100%} img { height: 70px; width: inherit!important; max-width:50%; } h3{ font-size: 10px; margin-top: 5px !important; } svg{width: 100% !important; height: auto !important; margin: 0 auto;} #imprimir-v2 { width: "100%"; }</style></head><body >');
    mywindow.document.write(document.getElementById(div).innerHTML);
    mywindow.document.write('</body></html>');
    //mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    //      mywindow.close();
  }

  const render = () => {
    return <div>
          <Button size="sm" style={{ marginBottom: 10, marginRight: 10 }} variant="secondary" onClick={() => handlePrint()}><i className="fas fa-barcode mb-0"></i> IMPRIMIR DESDE PC</Button>
          <Button size="sm" style={{ marginBottom: 10, marginRight: 10 }} variant="primary" onClick={() => imprimirPedidoActual("imprimir-v2")}><i className="fas fa-barcode mb-0"></i> IMPRIMIR DESDE TABLET</Button>
         <MostrarEtiquetas etiquetas={etiquetas} ref={componentRef} />
    </div>
  }

  return (
    <div>
      {render()}
    </div>
  );
}

export default Etiqueta