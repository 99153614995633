import { useEffect, useState } from "react"
import { Card, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import { urlapi } from "../../lib/backend/data"

const Inventario = (props) => {
    const sku = props.sku ? props.sku : false
    const id = props.id ? props.id : false
    const simple = props.simple ? props.simple : false
    const [ inventario, setInventario ] = useState([])
    const [ loading, setLoading ] = useState(true)

    const obtenerDatos = async () => {
        if(!id) {
            setLoading(false)
            return false
        }
        const url = `${urlapi}/productos/stock`
        return fetch(url,{
            method: "POST",
            body: JSON.stringify({
              id,
              sku
            }),
            headers: {
                'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then(resultados => {
            console.log(resultados)
            if(!resultados){
              toast.error("Sin datos del servidor")
            } else if(resultados.errorMessage){
              toast.error(resultados.errorMessage)
            } else if(Array.isArray(resultados)){
                setInventario(resultados)
            }
            return setLoading(false)
          })
          .catch(error => {
            console.log(error)
            return setLoading(false)
          })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const MostrarInventarios = (props) => {
        const simple = props.simple ? props.simple : false
        if(loading) return <Spinner animation="border" />

        if(simple === true) return <div>
            {
              inventario.map((inv,i) => {
                return <div key={`${inv.name}-${i}`}>
                  <p className="mb-0">{inv.name}: <b>{inv.stock}</b></p>
                  {(i + 1) === inventario.length ? false : <hr className="mb-2 mt-2"/> }
                </div>
              })
            }
        </div>

        return <div>
            {
              inventario.map((inv,i) => {
                return <div key={`${inv.name}-${i}`}>

                  <p className="mb-0">{inv.name}</p>
                  <h4 className="mb-0">{inv.stock}</h4>
                  {(i + 1) === inventario.length ? false : <hr className="mb-2 mt-2"/> }
                </div>
              })
            }
        </div>
    }

    if(simple === true) return <div>
        <MostrarInventarios simple={simple} />
    </div>

    return <Card className="p-2 mb-3">
        <h6 className="mb-0">Inventarios</h6>
        <MostrarInventarios />
    </Card>
}

export default Inventario